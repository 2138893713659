@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500&display=swap);
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::after,
::before {
  --tw-content: "";
}
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
}
body {
  margin: 0;
  line-height: inherit;
}
hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}
[role="button"],
button {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
}
[hidden] {
  display: none;
}
::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 45px;
    padding-left: 45px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 13rem;
    padding-left: 13rem;
  }
}
.pointer-events-none {
  pointer-events: none !important;
}
.invisible {
  visibility: hidden !important;
}
.collapse {
  visibility: collapse !important;
}
.static {
  position: static !important;
}
.fixed {
  position: fixed !important;
}
.absolute {
  position: absolute !important;
}
.relative {
  position: relative !important;
}
.sticky {
  position: sticky !important;
}
.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}
.inset-2 {
  top: 0.5rem !important;
  right: 0.5rem !important;
  bottom: 0.5rem !important;
  left: 0.5rem !important;
}
.bottom-3 {
  bottom: 0.75rem !important;
}
.bottom-5 {
  bottom: 1.25rem !important;
}
.top-3 {
  top: 0.75rem !important;
}
.top-20 {
  top: 5rem !important;
}
.top-\[2px\] {
  top: 2px !important;
}
.-top-0 {
  top: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.left-0 {
  left: 0 !important;
}
.-bottom-\[2px\] {
  bottom: -2px !important;
}
.top-2\/4 {
  top: 50% !important;
}
.bottom-2\/4 {
  bottom: 50% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.-top-7 {
  top: -1.75rem !important;
}
.top-0 {
  top: 0 !important;
}
.-top-5 {
  top: -1.25rem !important;
}
.-left-5 {
  left: -1.25rem !important;
}
.-bottom-5 {
  bottom: -1.25rem !important;
}
.-right-5 {
  right: -1.25rem !important;
}
.-top-\[350px\] {
  top: -350px !important;
}
.-bottom-\[350px\] {
  bottom: -350px !important;
}
.-left-\[100px\] {
  left: -100px !important;
}
.-right-\[100px\] {
  right: -100px !important;
}
.bottom-24 {
  bottom: 6rem !important;
}
.top-1\/2 {
  top: 50% !important;
}
.bottom-1\/2 {
  bottom: 50% !important;
}
.top-16 {
  top: 4rem !important;
}
.bottom-16 {
  bottom: 4rem !important;
}
.top-4 {
  top: 1rem !important;
}
.right-1\/2 {
  right: 50% !important;
}
.-bottom-10 {
  bottom: -2.5rem !important;
}
.-bottom-20 {
  bottom: -5rem !important;
}
.-top-20 {
  top: -5rem !important;
}
.-left-0 {
  left: 0 !important;
}
.bottom-28 {
  bottom: 7rem !important;
}
.-right-0 {
  right: 0 !important;
}
.top-\[48\%\] {
  top: 48% !important;
}
.bottom-6 {
  bottom: 1.5rem !important;
}
.right-6 {
  right: 1.5rem !important;
}
.left-6 {
  left: 1.5rem !important;
}
.-bottom-12 {
  bottom: -3rem !important;
}
.top-5 {
  top: 1.25rem !important;
}
.-bottom-16 {
  bottom: -4rem !important;
}
.bottom-\[25px\] {
  bottom: 25px !important;
}
.bottom-4 {
  bottom: 1rem !important;
}
.top-1\/4 {
  top: 25% !important;
}
.top-40 {
  top: 10rem !important;
}
.right-\[3px\] {
  right: 3px !important;
}
.top-1\/3 {
  top: 33.333333% !important;
}
.-bottom-52 {
  bottom: -13rem !important;
}
.right-2 {
  right: 0.5rem !important;
}
.left-2 {
  left: 0.5rem !important;
}
.top-6 {
  top: 1.5rem !important;
}
.bottom-20 {
  bottom: 5rem !important;
}
.bottom-32 {
  bottom: 8rem !important;
}
.bottom-36 {
  bottom: 9rem !important;
}
.top-\[47\%\] {
  top: 47% !important;
}
.-top-\[10px\] {
  top: -10px !important;
}
.left-3 {
  left: 0.75rem !important;
}
.top-2 {
  top: 0.5rem !important;
}
.top-\[30\%\] {
  top: 30% !important;
}
.-right-2 {
  right: -0.5rem !important;
}
.left-\[2px\] {
  left: 2px !important;
}
.top-\[40\%\] {
  top: 40% !important;
}
.-right-3 {
  right: -0.75rem !important;
}
.z-10 {
  z-index: 10 !important;
}
.-z-1 {
  z-index: -1 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
.z-30 {
  z-index: 30 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-50 {
  z-index: 50 !important;
}
.z-40 {
  z-index: 40 !important;
}
.z-20 {
  z-index: 20 !important;
}
.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}
.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}
.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}
.col-span-6 {
  grid-column: span 6 / span 6 !important;
}
.col-span-5 {
  grid-column: span 5 / span 5 !important;
}
.col-span-7 {
  grid-column: span 7 / span 7 !important;
}
.col-span-12 {
  grid-column: span 12 / span 12 !important;
}
.m-auto {
  margin: auto !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-0 {
  margin: 0 !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.-m-3 {
  margin: -0.75rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.mx-1\.5 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-12 {
  margin-top: 3rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mt-16 {
  margin-top: 4rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-10 {
  margin-bottom: 2.5rem !important;
}
.mt-7 {
  margin-top: 1.75rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.-mt-32 {
  margin-top: -8rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.-mt-\[200px\] {
  margin-top: -200px !important;
}
.-mt-\[140px\] {
  margin-top: -140px !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mb-16 {
  margin-bottom: 4rem !important;
}
.mb-12 {
  margin-bottom: 3rem !important;
}
.mt-\[74px\] {
  margin-top: 74px !important;
}
.-mb-96 {
  margin-bottom: -24rem !important;
}
.-mt-28 {
  margin-top: -7rem !important;
}
.mt-32 {
  margin-top: 8rem !important;
}
.-mt-10 {
  margin-top: -2.5rem !important;
}
.mt-20 {
  margin-top: 5rem !important;
}
.-mt-16 {
  margin-top: -4rem !important;
}
.mt-28 {
  margin-top: 7rem !important;
}
.-mt-\[299px\] {
  margin-top: -299px !important;
}
.-mt-\[150px\] {
  margin-top: -150px !important;
}
.-mt-\[10px\] {
  margin-top: -10px !important;
}
.mt-\[30px\] {
  margin-top: 30px !important;
}
.mt-\[30\] {
  margin-top: 30 !important;
}
.mt-24 {
  margin-top: 6rem !important;
}
.mt-\[10px\] {
  margin-top: 10px !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}
.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.table {
  display: table !important;
}
.grid {
  display: grid !important;
}
.hidden {
  display: none !important;
}
.h-28 {
  height: 7rem !important;
}
.h-\[14px\] {
  height: 14px !important;
}
.h-4 {
  height: 1rem !important;
}
.h-screen {
  height: 100vh !important;
}
.h-9 {
  height: 2.25rem !important;
}
.h-5 {
  height: 1.25rem !important;
}
.h-11 {
  height: 2.75rem !important;
}
.h-24 {
  height: 6rem !important;
}
.h-16 {
  height: 4rem !important;
}
.h-\[50px\] {
  height: 50px !important;
}
.h-\[46px\] {
  height: 46px !important;
}
.h-auto {
  height: auto !important;
}
.h-\[40px\] {
  height: 40px !important;
}
.h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.h-\[200px\] {
  height: 200px !important;
}
.h-6 {
  height: 1.5rem !important;
}
.h-20 {
  height: 5rem !important;
}
.h-\[500px\] {
  height: 500px !important;
}
.h-10 {
  height: 2.5rem !important;
}
.h-12 {
  height: 3rem !important;
}
.h-14 {
  height: 3.5rem !important;
}
.h-7 {
  height: 1.75rem !important;
}
.h-\[320px\] {
  height: 320px !important;
}
.h-8 {
  height: 2rem !important;
}
.h-full {
  height: 100% !important;
}
.h-48 {
  height: 12rem !important;
}
.h-\[600px\] {
  height: 600px !important;
}
.h-\[400px\] {
  height: 400px !important;
}
.h-\[65px\] {
  height: 65px !important;
}
.h-\[6px\] {
  height: 6px !important;
}
.h-4\/5 {
  height: 80% !important;
}
.h-56 {
  height: 14rem !important;
}
.h-52 {
  height: 13rem !important;
}
.h-\[45px\] {
  height: 45px !important;
}
.h-32 {
  height: 8rem !important;
}
.h-\[84px\] {
  height: 84px !important;
}
.h-\[56\.25vw\] {
  height: 56.25vw !important;
}
.h-3 {
  height: 0.75rem !important;
}
.h-\[5000px\] {
  height: 5000px !important;
}
.h-\[60px\] {
  height: 60px !important;
}
.h-\[512px\] {
  height: 512px !important;
}
.h-\[110px\] {
  height: 110px !important;
}
.\!h-12 {
  height: 3rem !important;
}
.h-\[56px\] {
  height: 56px !important;
}
.h-\[24px\] {
  height: 24px !important;
}
.h-\[28px\] {
  height: 28px !important;
}
.h-\[98\%\] {
  height: 98% !important;
}
.h-80 {
  height: 20rem !important;
}
.max-h-6 {
  max-height: 1.5rem !important;
}
.min-h-full {
  min-height: 100% !important;
}
.min-h-screen {
  min-height: 100vh !important;
}
.w-full {
  width: 100% !important;
}
.w-28 {
  width: 7rem !important;
}
.w-\[14px\] {
  width: 14px !important;
}
.w-4 {
  width: 1rem !important;
}
.w-9 {
  width: 2.25rem !important;
}
.w-11 {
  width: 2.75rem !important;
}
.w-24 {
  width: 6rem !important;
}
.w-\[40px\] {
  width: 40px !important;
}
.w-6 {
  width: 1.5rem !important;
}
.w-20 {
  width: 5rem !important;
}
.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.w-10 {
  width: 2.5rem !important;
}
.w-12 {
  width: 3rem !important;
}
.w-14 {
  width: 3.5rem !important;
}
.w-7 {
  width: 1.75rem !important;
}
.w-16 {
  width: 4rem !important;
}
.w-\[320px\] {
  width: 320px !important;
}
.w-5 {
  width: 1.25rem !important;
}
.w-auto {
  width: auto !important;
}
.w-8 {
  width: 2rem !important;
}
.w-48 {
  width: 12rem !important;
}
.w-\[600px\] {
  width: 600px !important;
}
.w-\[400px\] {
  width: 400px !important;
}
.w-60 {
  width: 15rem !important;
}
.w-44 {
  width: 11rem !important;
}
.w-64 {
  width: 16rem !important;
}
.w-52 {
  width: 13rem !important;
}
.w-56 {
  width: 14rem !important;
}
.w-32 {
  width: 8rem !important;
}
.w-\[84px\] {
  width: 84px !important;
}
.w-72 {
  width: 18rem !important;
}
.w-screen {
  width: 100vw !important;
}
.w-1\/2 {
  width: 50% !important;
}
.w-\[280px\] {
  width: 280px !important;
}
.w-\[200px\] {
  width: 200px !important;
}
.w-\[512px\] {
  width: 512px !important;
}
.w-\[110px\] {
  width: 110px !important;
}
.w-1\/3 {
  width: 33.333333% !important;
}
.w-\[98\%\] {
  width: 98% !important;
}
.min-w-full {
  min-width: 100% !important;
}
.min-w-\[65px\] {
  min-width: 65px !important;
}
.min-w-\[75px\] {
  min-width: 75px !important;
}
.min-w-\[45px\] {
  min-width: 45px !important;
}
.min-w-\[177\.77vw\] {
  min-width: 177.77vw !important;
}
.min-w-\[200px\] {
  min-width: 200px !important;
}
.min-w-\[540px\] {
  min-width: 540px !important;
}
.min-w-\[180px\] {
  min-width: 180px !important;
}
.min-w-\[120px\] {
  min-width: 120px !important;
}
.min-w-\[64px\] {
  min-width: 64px !important;
}
.min-w-\[56px\] {
  min-width: 56px !important;
}
.min-w-\[80px\] {
  min-width: 80px !important;
}
.max-w-\[400px\] {
  max-width: 400px !important;
}
.max-w-xl {
  max-width: 36rem !important;
}
.max-w-2xl {
  max-width: 42rem !important;
}
.max-w-md {
  max-width: 28rem !important;
}
.max-w-\[440px\] {
  max-width: 440px !important;
}
.max-w-lg {
  max-width: 32rem !important;
}
.flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 1 0% !important;
}
.shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.-translate-y-2\/4 {
  --tw-translate-y: -50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-2\/4 {
  --tw-translate-y: 50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-y-1\/2 {
  --tw-translate-y: -50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-y-1\/2 {
  --tw-translate-y: 50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-1\/2 {
  --tw-translate-x: 50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-translate-x-full {
  --tw-translate-x: -100% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-full {
  --tw-translate-x: 100% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.translate-x-0 {
  --tw-translate-x: 0px !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-180 {
  --tw-rotate: 180deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-45 {
  --tw-rotate: 45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-rotate-45 {
  --tw-rotate: -45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-\[30deg\] {
  --tw-rotate: 30deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.rotate-90 {
  --tw-rotate: 90deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.-rotate-90 {
  --tw-rotate: -90deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.scale-0 {
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animate-\[spin_30s_linear_infinite\] {
  -webkit-animation: 30s linear infinite spin !important;
  animation: 30s linear infinite spin !important;
}
.animate-\[spin_10s_linear_infinite\] {
  -webkit-animation: 10s linear infinite spin !important;
  animation: 10s linear infinite spin !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.resize {
  resize: both !important;
}
.list-none {
  list-style-type: none !important;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}
.flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.justify-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.gap-\[30px\] {
  gap: 30px !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-\[24px\] {
  gap: 24px !important;
}
.gap-\[30\] {
  gap: 30 !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-x-\[30px\] {
  -webkit-column-gap: 30px !important;
  -moz-column-gap: 30px !important;
  column-gap: 30px !important;
}
.gap-y-\[50px\] {
  row-gap: 50px !important;
}
.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}
.self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.scroll-smooth {
  scroll-behavior: smooth !important;
}
.whitespace-nowrap {
  white-space: nowrap !important;
}
.rounded-md {
  border-radius: 0.375rem !important;
}
.rounded-full {
  border-radius: 9999px !important;
}
.rounded-\[30px\] {
  border-radius: 30px !important;
}
.rounded-xl {
  border-radius: 0.75rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.5rem !important;
}
.rounded-\[100px\] {
  border-radius: 100px !important;
}
.rounded-3xl {
  border-radius: 1.5rem !important;
}
.rounded-2xl {
  border-radius: 1rem !important;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}
.rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}
.rounded-br-\[30px\] {
  border-bottom-right-radius: 30px !important;
}
.border {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-0 {
  border-width: 0 !important;
}
.border-\[3px\] {
  border-width: 3px !important;
}
.border-8 {
  border-width: 8px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-x-4 {
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}
.border-y {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}
.border-t {
  border-top-width: 1px !important;
}
.border-b {
  border-bottom-width: 1px !important;
}
.border-b-\[3px\] {
  border-bottom-width: 3px !important;
}
.border-t-\[3px\] {
  border-top-width: 3px !important;
}
.border-t-8 {
  border-top-width: 8px !important;
}
.border-dashed {
  border-style: dashed !important;
}
.border-none {
  border-style: none !important;
}
.border-inherit {
  border-color: inherit !important;
}
.border-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
.border-green-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}
.border-gray-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}
.border-indigo-600\/10 {
  border-color: rgb(79 70 229 / 0.1) !important;
}
.border-slate-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}
.border-white\/30 {
  border-color: rgb(255 255 255 / 0.3) !important;
}
.border-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}
.border-indigo-600\/50 {
  border-color: rgb(79 70 229 / 0.5) !important;
}
.border-slate-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}
.border-indigo-600\/20 {
  border-color: rgb(79 70 229 / 0.2) !important;
}
.border-red-600\/10 {
  border-color: rgb(220 38 38 / 0.1) !important;
}
.border-gray-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}
.border-slate-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}
.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-indigo-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}
.bg-emerald-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}
.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.bg-white\/70 {
  background-color: rgb(255 255 255 / 0.7) !important;
}
.bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 28 45 / var(--tw-bg-opacity)) !important;
}
.bg-black\/70 {
  background-color: rgb(22 28 45 / 0.7) !important;
}
.bg-indigo-600\/5 {
  background-color: rgb(79 70 229 / 0.05) !important;
}
.bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}
.bg-slate-900\/50 {
  background-color: rgb(15 23 42 / 0.5) !important;
}
.bg-indigo-600\/10 {
  background-color: rgb(79 70 229 / 0.1) !important;
}
.bg-dark {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(60 72 88 / var(--tw-bg-opacity)) !important;
}
.bg-slate-900\/40 {
  background-color: rgb(15 23 42 / 0.4) !important;
}
.bg-slate-900\/70 {
  background-color: rgb(15 23 42 / 0.7) !important;
}
.bg-indigo-600\/20 {
  background-color: rgb(79 70 229 / 0.2) !important;
}
.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}
.bg-white\/30 {
  background-color: rgb(255 255 255 / 0.3) !important;
}
.bg-slate-900\/30 {
  background-color: rgb(15 23 42 / 0.3) !important;
}
.bg-emerald-600\/5 {
  background-color: rgb(5 150 105 / 0.05) !important;
}
.bg-red-600\/5 {
  background-color: rgb(220 38 38 / 0.05) !important;
}
.bg-sky-600\/5 {
  background-color: rgb(2 132 199 / 0.05) !important;
}
.bg-amber-600\/5 {
  background-color: rgb(217 119 6 / 0.05) !important;
}
.bg-black\/50 {
  background-color: rgb(22 28 45 / 0.5) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05) !important;
}
.bg-indigo-600\/90 {
  background-color: rgb(79 70 229 / 0.9) !important;
}
.bg-slate-900\/60 {
  background-color: rgb(15 23 42 / 0.6) !important;
}
.bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}
.bg-sky-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity)) !important;
}
.bg-amber-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity)) !important;
}
.bg-slate-900\/20 {
  background-color: rgb(15 23 42 / 0.2) !important;
}
.bg-orange-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity)) !important;
}
.bg-slate-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}
.bg-black\/80 {
  background-color: rgb(22 28 45 / 0.8) !important;
}
.bg-slate-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity)) !important;
}
.bg-slate-200\/40 {
  background-color: rgb(226 232 240 / 0.4) !important;
}
.bg-black\/60 {
  background-color: rgb(22 28 45 / 0.6) !important;
}
.bg-white\/60 {
  background-color: rgb(255 255 255 / 0.6) !important;
}
.bg-black\/25 {
  background-color: rgb(22 28 45 / 0.25) !important;
}
.bg-emerald-600\/10 {
  background-color: rgb(5 150 105 / 0.1) !important;
}
.bg-red-600\/10 {
  background-color: rgb(220 38 38 / 0.1) !important;
}
.bg-sky-600\/10 {
  background-color: rgb(2 132 199 / 0.1) !important;
}
.bg-slate-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.bg-gray-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
}
.bg-white\/50 {
  background-color: rgb(255 255 255 / 0.5) !important;
}
.bg-dark-footer {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(25 33 50 / var(--tw-bg-opacity)) !important;
}
.bg-gray-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5 !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/cta\.jpg\'\)\] {
  background-image: url("../images/cta.jpg") !important;
}
.bg-gradient-to-b {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to bottom,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/blog\/bg\.jpg\'\)\] {
  background-image: url("../images/blog/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/company\/aboutus\.jpg\'\)\] {
  background-image: url("../images/company/aboutus.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/course\/cta\.jpg\'\)\] {
  background-image: url("../images/course/cta.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/food\/blog\/recipe\.jpg\'\)\] {
  background-image: url("../images/food/blog/recipe.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/helpcenter\.jpg\'\)\] {
  background-image: url("../images/helpcenter.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/app\/bg\.png\'\)\] {
  background-image: url("../images/app/bg.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/blog\/bg1\.jpg\'\)\] {
  background-image: url("../images/blog/bg1.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/business\/bg01\.jpg\'\)\] {
  background-image: url("../images/business/bg01.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/business\/bg02\.jpg\'\)\] {
  background-image: url("../images/business/bg02.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/business\/bg03\.jpg\'\)\] {
  background-image: url("../images/business/bg03.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/team\.jpg\'\)\] {
  background-image: url("../images/team.jpg") !important;
}
.bg-gradient-to-tl {
  background-image: -webkit-gradient(
    linear,
    right bottom,
    left top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to top left,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-gradient-to-tr {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to top right,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/construction\/bg\.jpg\'\)\] {
  background-image: url("../images/construction/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/construction\/bg2\.jpg\'\)\] {
  background-image: url("../images/construction/bg2.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/consulting\/bg\.jpg\'\)\] {
  background-image: url("../images/consulting/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/map\.png\'\)\] {
  background-image: url("../images/map.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/corporate\/bg\.jpg\'\)\] {
  background-image: url("../images/corporate/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/overlay\.png\'\)\] {
  background-image: url("../images/overlay.png") !important;
}
.bg-gradient-to-r {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to right,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/map\.svg\'\)\] {
  background-image: url("../images/map.svg") !important;
}
.bg-gradient-to-t {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to top,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/crypto\/bg1\.jpg\'\)\] {
  background-image: url("../images/crypto/bg1.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/crypto\/bg2\.jpg\'\)\] {
  background-image: url("../images/crypto/bg2.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/crypto\/cta\.jpg\'\)\] {
  background-image: url("../images/crypto/cta.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/digital\/bg01\.jpg\'\)\] {
  background-image: url("../images/digital/bg01.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/event\/bg\.jpg\'\)\] {
  background-image: url("../images/event/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/event\/bg3\.jpg\'\)\] {
  background-image: url("../images/event/bg3.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/event\/bg2\.jpg\'\)\] {
  background-image: url("../images/event/bg2.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/gym\/bg1\.jpg\'\)\] {
  background-image: url("../images/gym/bg1.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/gym\/cta\.jpg\'\)\] {
  background-image: url("../images/gym/cta.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/hospital\/bg\.jpg\'\)\] {
  background-image: url("../images/hospital/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/hosting\/bg-hosting\.jpg\'\)\] {
  background-image: url("../images/hosting/bg-hosting.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/hosting\/bg\.png\'\)\] {
  background-image: url("../images/hosting/bg.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/insurance\/cta\.png\'\)\] {
  background-image: url("../images/insurance/cta.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/insurance\/bg\.jpg\'\)\] {
  background-image: url("../images/insurance/bg.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/bg2\.png\'\)\] {
  background-image: url("../images/bg2.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/job\/job\.jpg\'\)\] {
  background-image: url("../images/job/job.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/job\/curve-shape\.png\'\)\] {
  background-image: url("../images/job/curve-shape.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/bg4\.png\'\)\] {
  background-image: url("../images/bg4.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/bg\.png\'\)\] {
  background-image: url("../images/bg.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/bg-video\.png\'\)\] {
  background-image: url("../images/bg-video.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/saas\/home\.jpg\'\)\] {
  background-image: url("../images/saas/home.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/saas\/cta\.jpg\'\)\] {
  background-image: url("../images/saas/cta.jpg") !important;
}
.bg-gradient-to-br {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to bottom right,
    var(--tw-gradient-stops)
  ) !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/photography\/photographer\.jpg\'\)\] {
  background-image: url("../images/photography/photographer.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/food\/bg01\.jpg\'\)\] {
  background-image: url("../images/food/bg01.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/food\/menu\.jpg\'\)\] {
  background-image: url("../images/food/menu.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/food\/food-menu\.png\'\)\] {
  background-image: url("../images/food/food-menu.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/food\/cta\.jpg\'\)\] {
  background-image: url("../images/food/cta.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/bg-seo\.png\'\)\] {
  background-image: url("../images/bg-seo.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/home-shape\.png\'\)\] {
  background-image: url("../images/home-shape.png") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/utility\.jpg\'\)\] {
  background-image: url("../images/utility.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/services\.jpg\'\)\] {
  background-image: url("../images/services.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/portfolio\/bg-inner\.jpg\'\)\] {
  background-image: url("../images/portfolio/bg-inner.jpg") !important;
}
.bg-\[url\(\'\.\.\/\.\.\/assets\/images\/real\/bg\/01\.jpg\'\)\] {
  background-image: url("../images/real/bg/01.jpg") !important;
}
.bg-none {
  background-image: none !important;
}
.from-transparent {
  --tw-gradient-from: transparent !important;
  --tw-gradient-to: rgb(0 0 0 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600 {
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-500 {
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-to: rgb(99 102 241 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-gray-900 {
  --tw-gradient-from: #111827 !important;
  --tw-gradient-to: rgb(17 24 39 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-black {
  --tw-gradient-from: #161c2d !important;
  --tw-gradient-to: rgb(22 28 45 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600\/90 {
  --tw-gradient-from: rgb(79 70 229 / 0.9) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-black\/60 {
  --tw-gradient-from: rgb(22 28 45 / 0.6) !important;
  --tw-gradient-to: rgb(22 28 45 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600\/20 {
  --tw-gradient-from: rgb(79 70 229 / 0.2) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600\/70 {
  --tw-gradient-from: rgb(79 70 229 / 0.7) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-green-600\/70 {
  --tw-gradient-from: rgb(22 163 74 / 0.7) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-purple-600\/70 {
  --tw-gradient-from: rgb(147 51 234 / 0.7) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-blue-600\/70 {
  --tw-gradient-from: rgb(37 99 235 / 0.7) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-yellow-600\/70 {
  --tw-gradient-from: rgb(202 138 4 / 0.7) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-rose-600\/70 {
  --tw-gradient-from: rgb(225 29 72 / 0.7) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-fuchsia-600 {
  --tw-gradient-from: #c026d3 !important;
  --tw-gradient-to: rgb(192 38 211 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600\/30 {
  --tw-gradient-from: rgb(79 70 229 / 0.3) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-indigo-600\/10 {
  --tw-gradient-from: rgb(79 70 229 / 0.1) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-slate-900 {
  --tw-gradient-from: #0f172a !important;
  --tw-gradient-to: rgb(15 23 42 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-white {
  --tw-gradient-from: #fff !important;
  --tw-gradient-to: rgb(255 255 255 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.from-red-600 {
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-to: rgb(220 38 38 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.via-indigo-600\/70 {
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.7),
    var(--tw-gradient-to) !important;
}
.via-gray-900\/50 {
  --tw-gradient-to: rgb(17 24 39 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.5),
    var(--tw-gradient-to) !important;
}
.via-slate-900\/50 {
  --tw-gradient-to: rgb(15 23 42 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.5),
    var(--tw-gradient-to) !important;
}
.via-black\/80 {
  --tw-gradient-to: rgb(22 28 45 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 28 45 / 0.8),
    var(--tw-gradient-to) !important;
}
.via-fuchsia-600\/20 {
  --tw-gradient-to: rgb(192 38 211 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.2),
    var(--tw-gradient-to) !important;
}
.via-fuchsia-600\/30 {
  --tw-gradient-to: rgb(192 38 211 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.3),
    var(--tw-gradient-to) !important;
}
.via-transparent {
  --tw-gradient-to: rgb(0 0 0 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to) !important;
}
.via-white\/80 {
  --tw-gradient-to: rgb(255 255 255 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.8),
    var(--tw-gradient-to) !important;
}
.via-black\/50 {
  --tw-gradient-to: rgb(22 28 45 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 28 45 / 0.5),
    var(--tw-gradient-to) !important;
}
.via-black\/40 {
  --tw-gradient-to: rgb(22 28 45 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 28 45 / 0.4),
    var(--tw-gradient-to) !important;
}
.via-white {
  --tw-gradient-to: rgb(255 255 255 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to) !important;
}
.to-black {
  --tw-gradient-to: #161c2d !important;
}
.to-indigo-600\/20 {
  --tw-gradient-to: rgb(79 70 229 / 0.2) !important;
}
.to-indigo-600 {
  --tw-gradient-to: #4f46e5 !important;
}
.to-transparent {
  --tw-gradient-to: transparent !important;
}
.to-indigo-600\/10 {
  --tw-gradient-to: rgb(79 70 229 / 0.1) !important;
}
.to-fuchsia-600\/90 {
  --tw-gradient-to: rgb(192 38 211 / 0.9) !important;
}
.to-slate-900 {
  --tw-gradient-to: #0f172a !important;
}
.to-orange-600\/20 {
  --tw-gradient-to: rgb(234 88 12 / 0.2) !important;
}
.to-orange-600\/70 {
  --tw-gradient-to: rgb(234 88 12 / 0.7) !important;
}
.to-red-600\/70 {
  --tw-gradient-to: rgb(220 38 38 / 0.7) !important;
}
.to-fuchsia-600\/70 {
  --tw-gradient-to: rgb(192 38 211 / 0.7) !important;
}
.to-pink-600\/70 {
  --tw-gradient-to: rgb(219 39 119 / 0.7) !important;
}
.to-violet-600\/70 {
  --tw-gradient-to: rgb(124 58 237 / 0.7) !important;
}
.to-sky-600\/70 {
  --tw-gradient-to: rgb(2 132 199 / 0.7) !important;
}
.to-lime-600\/70 {
  --tw-gradient-to: rgb(101 163 13 / 0.7) !important;
}
.to-orange-600\/30 {
  --tw-gradient-to: rgb(234 88 12 / 0.3) !important;
}
.bg-cover {
  background-size: cover !important;
}
.bg-fixed {
  background-attachment: fixed !important;
}
.bg-clip-text {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}
.bg-center {
  background-position: center !important;
}
.bg-top {
  background-position: top !important;
}
.bg-right {
  background-position: right !important;
}
.bg-bottom {
  background-position: bottom !important;
}
.bg-repeat {
  background-repeat: repeat !important;
}
.bg-no-repeat {
  background-repeat: no-repeat !important;
}
.fill-indigo-600\/5 {
  fill: rgb(79 70 229 / 0.05) !important;
}
.stroke-1 {
  stroke-width: 1 !important;
}
.object-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-\[30px\] {
  padding: 30px !important;
}
.p-1\.5 {
  padding: 0.375rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.px-2\.5 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}
.py-0\.5 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}
.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-52 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}
.py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}
.py-44 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.py-0\.75 {
  padding-top: 0.1875rem !important;
  padding-bottom: 0.1875rem !important;
}
.py-\[60px\] {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-\[30px\] {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.pb-16 {
  padding-bottom: 4rem !important;
}
.pt-36 {
  padding-top: 9rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-40 {
  padding-top: 10rem !important;
}
.pb-56 {
  padding-bottom: 14rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-16 {
  padding-top: 4rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pb-24 {
  padding-bottom: 6rem !important;
}
.pr-40 {
  padding-right: 10rem !important;
}
.pl-6 {
  padding-left: 1.5rem !important;
}
.pt-12 {
  padding-top: 3rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pt-28 {
  padding-top: 7rem !important;
}
.pt-48 {
  padding-top: 12rem !important;
}
.pb-60 {
  padding-bottom: 15rem !important;
}
.pb-32 {
  padding-bottom: 8rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-\[60px\] {
  padding-bottom: 60px !important;
}
.text-center {
  text-align: center !important;
}
.text-start {
  text-align: start !important;
}
.align-middle {
  vertical-align: middle !important;
}
.font-nunito {
  font-family: Nunito, sans-serif !important;
}
.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}
.text-8xl {
  font-size: 6rem !important;
  line-height: 1 !important;
}
.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.text-\[26px\] {
  font-size: 26px !important;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}
.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}
.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}
.text-\[13px\] {
  font-size: 13px !important;
}
.text-\[20px\] {
  font-size: 20px !important;
}
.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}
.text-\[30px\] {
  font-size: 30px !important;
}
.text-7xl {
  font-size: 4.5rem !important;
  line-height: 1 !important;
}
.text-6xl {
  font-size: 3.75rem !important;
  line-height: 1 !important;
}
.text-9xl {
  font-size: 8rem !important;
  line-height: 1 !important;
}
.text-\[28px\] {
  font-size: 28px !important;
}
.text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}
.text-\[10px\] {
  font-size: 10px !important;
}
.text-\[18px\] {
  font-size: 18px !important;
}
.text-\[12px\] {
  font-size: 12px !important;
}
.text-\[50px\] {
  font-size: 50px !important;
}
.text-\[40px\] {
  font-size: 40px !important;
}
.text-\[56px\] {
  font-size: 56px !important;
}
.text-\[22px\] {
  font-size: 22px !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-extrabold {
  font-weight: 800 !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.italic {
  font-style: italic !important;
}
.leading-9 {
  line-height: 2.25rem !important;
}
.leading-normal {
  line-height: 1.5 !important;
}
.leading-\[0\] {
  line-height: 0 !important;
}
.leading-none {
  line-height: 1 !important;
}
.leading-8 {
  line-height: 2rem !important;
}
.leading-snug {
  line-height: 1.375 !important;
}
.leading-\[95px\] {
  line-height: 95px !important;
}
.tracking-wide {
  letter-spacing: 0.025em !important;
}
.tracking-\[0\.5px\] {
  letter-spacing: 0.5px !important;
}
.tracking-\[1px\] {
  letter-spacing: 1px !important;
}
.text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
.text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}
.text-indigo-600\/10 {
  color: rgb(79 70 229 / 0.1) !important;
}
.text-white\/50 {
  color: rgb(255 255 255 / 0.5) !important;
}
.text-indigo-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
.text-emerald-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-text-opacity)) !important;
}
.text-amber-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 158 11 / var(--tw-text-opacity)) !important;
}
.text-sky-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(14 165 233 / var(--tw-text-opacity)) !important;
}
.text-white\/60 {
  color: rgb(255 255 255 / 0.6) !important;
}
.text-white\/70 {
  color: rgb(255 255 255 / 0.7) !important;
}
.text-red-600\/20 {
  color: rgb(220 38 38 / 0.2) !important;
}
.text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
}
.text-amber-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 191 36 / var(--tw-text-opacity)) !important;
}
.text-dark-footer {
  --tw-text-opacity: 1 !important;
  color: rgb(25 33 50 / var(--tw-text-opacity)) !important;
}
.text-white\/80 {
  color: rgb(255 255 255 / 0.8) !important;
}
.text-slate-900\/5 {
  color: rgb(15 23 42 / 0.05) !important;
}
.text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
}
.text-transparent {
  color: transparent !important;
}
.text-white\/75 {
  color: rgb(255 255 255 / 0.75) !important;
}
.text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-text-opacity)) !important;
}
.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.text-dark {
  --tw-text-opacity: 1 !important;
  color: rgb(60 72 88 / var(--tw-text-opacity)) !important;
}
.text-amber-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(217 119 6 / var(--tw-text-opacity)) !important;
}
.text-dark\/\[0\.03\] {
  color: rgb(60 72 88 / 0.03) !important;
}
.text-gray-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}
.text-slate-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(241 245 249 / var(--tw-text-opacity)) !important;
}
.text-slate-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 250 252 / var(--tw-text-opacity)) !important;
}
.text-slate-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity)) !important;
}
.text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}
.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}
.text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}
.text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}
.text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}
.opacity-80 {
  opacity: 0.8 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-5 {
  opacity: 0.05 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-90 {
  opacity: 0.9 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-10 {
  opacity: 0.1 !important;
}
.opacity-30 {
  opacity: 0.3 !important;
}
.shadow-md {
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2) !important;
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow {
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-lg {
  --tw-shadow: 0 10px 25px -3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 10px 25px -3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-sm {
  --tw-shadow: 0 2px 4px 0 rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(60 72 88 / 0.1),
    0 8px 10px -6px rgb(60 72 88 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow-indigo-600\/10 {
  --tw-shadow-color: rgb(79 70 229 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.shadow-gray-100 {
  --tw-shadow-color: #f3f4f6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.outline-none {
  outline: transparent solid 2px !important;
  outline-offset: 2px !important;
}
.outline {
  outline-style: solid !important;
}
.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000) !important;
}
.ring-slate-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity)) !important;
}
.blur-\[200px\] {
  --tw-blur: blur(200px) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.grayscale {
  --tw-grayscale: grayscale(100%) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.transition-all {
  -webkit-transition-property: all !important;
  transition-property: all !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
  transition-duration: 150ms !important;
}
.transition {
  -webkit-transition-property: color, background-color, border-color, fill,
    stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  -webkit-transition-duration: 150ms !important;
  transition-duration: 150ms !important;
}
.duration-500 {
  -webkit-transition-duration: 0.5s !important;
  transition-duration: 0.5s !important;
}
.duration-700 {
  -webkit-transition-duration: 0.7s !important;
  transition-duration: 0.7s !important;
}
.duration-300 {
  -webkit-transition-duration: 0.3s !important;
  transition-duration: 0.3s !important;
}
.ease-in-out {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}
.ease-in {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}
p {
  line-height: 1.625;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}
::-moz-selection {
  background-color: rgb(79 70 229 / 0.9);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
::selection {
  background-color: rgb(79 70 229 / 0.9);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.btn {
  display: inline-block;
  border-width: 1px;
  padding: 0.5rem 1.25rem;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  -webkit-transition-property: color, background-color, border-color, fill,
    stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-box-shadow,
    -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn.btn-lg {
  padding: 0.625rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.btn.btn-sm {
  padding: 5px 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.btn.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 2.25rem;
  width: 2.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}
.btn.btn-icon.btn-lg {
  height: 3rem;
  width: 3rem;
}
.btn.btn-icon.btn-sm {
  height: 2rem;
  width: 2rem;
}
.btn.btn-link {
  position: relative;
  border-style: none;
  padding: 0;
}
.btn.btn-link::after {
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 0;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .btn.btn-link::after {
  right: 0;
  content: var(--tw-content);
  left: 0;
}
[dir="rtl"] .btn.btn-link::after {
  left: 0;
  content: var(--tw-content);
  right: 0;
}
.btn.btn-link:hover::after {
  content: var(--tw-content);
  width: 100%;
}
[dir="ltr"] .btn.btn-link:hover::after {
  content: var(--tw-content);
  right: auto;
}
[dir="rtl"] .btn.btn-link:hover::after {
  content: var(--tw-content);
  left: auto;
}
.breadcrumb .breadcrumb-item::before {
  --tw-content: "";
  content: var(--tw-content);
}
.breadcrumb .breadcrumb-item::after {
  font-size: 0.875rem;
  line-height: 1.25rem;
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
.breadcrumb .breadcrumb-item:last-child::after {
  content: var(--tw-content);
  display: none;
}
[dir="ltr"] .breadcrumb .breadcrumb-item::after {
  content: var(--tw-content);
  padding-left: 5px;
}
[dir="rtl"] .breadcrumb .breadcrumb-item::after {
  content: var(--tw-content);
  padding-right: 5px;
}
.dark .breadcrumb .breadcrumb-item::after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.breadcrumb .breadcrumb-item:after {
  content: "󰅂" !important;
  font-family: "Material Design Icons";
}
.breadcrumb.breadcrumb-light .breadcrumb-item::after {
  content: var(--tw-content);
  color: rgb(255 255 255 / 0.5);
}
#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  border-width: 0;
  background-color: transparent;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#topnav .logo {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
[dir="ltr"] #topnav .logo {
  float: left !important;
}
[dir="rtl"] #topnav .logo {
  float: right !important;
}
.dark #topnav .logo {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
#topnav .logo .l-dark {
  display: none;
}
#topnav .logo .l-light {
  display: inline-block;
}
#topnav .logo:focus {
  outline: transparent solid 2px;
  outline-offset: 2px;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu.active a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.dark #topnav .has-submenu.active a {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
#topnav .has-submenu.active .submenu li.active > a,
.dark #topnav .has-submenu.active .submenu li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
#topnav .has-submenu.active.active .menu-arrow,
.dark #topnav .has-submenu.active.active .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0 0.125rem 0.125rem 0;
  position: absolute;
  top: 15px;
  display: inline-block;
  border-radius: 0.5px;
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
  padding: 2px;
}
[dir="ltr"] #topnav .has-submenu .submenu .submenu-arrow {
  right: 1.25rem;
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="rtl"] #topnav .has-submenu .submenu .submenu-arrow {
  left: 1.25rem;
  --tw-rotate: 135deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.dark #topnav .has-submenu .submenu .submenu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  .submenu-arrow,
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  > .submenu-arrow,
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow,
.dark
  #topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  .submenu-arrow,
.dark
  #topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  > .submenu-arrow,
.dark #topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
#topnav .navbar-toggle {
  position: relative;
  margin: 0;
  cursor: pointer;
  border-width: 0;
  padding: 0;
}
#topnav .navbar-toggle .lines {
  position: relative;
  margin-top: 30px;
  margin-bottom: 26px;
  display: block;
  height: 18px;
  width: 25px;
}
[dir="ltr"] #topnav .navbar-toggle .lines {
  margin-right: 0;
  margin-left: 10px;
}
[dir="rtl"] #topnav .navbar-toggle .lines {
  margin-left: 0;
  margin-right: 10px;
}
#topnav .navbar-toggle span {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  margin-bottom: 5px;
  display: block;
  height: 2px;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(22 28 45 / var(--tw-bg-opacity));
}
.dark #topnav .navbar-toggle span {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}
#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  top: 6px;
  width: 100%;
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle:hover {
  background-color: transparent;
}
#topnav .buy-button {
  line-height: 74px;
}
[dir="ltr"] #topnav .buy-button {
  float: right;
}
[dir="rtl"] #topnav .buy-button {
  float: left;
}
#topnav .buy-button > li {
  line-height: initial;
}
#topnav .buy-button .btn-icon-dark,
#topnav .buy-button .login-btn-primary {
  display: none;
}
#topnav .buy-button .btn-icon-light,
#topnav .buy-button .login-btn-light {
  display: inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  position: relative;
}
#topnav .buy-button .search-bar .searchform::after {
  pointer-events: none;
  position: absolute;
  top: 14px;
  font-size: 1.25rem;
  content: var(--tw-content);
  line-height: 1.25rem;
}
[dir="ltr"] #topnav .buy-button .search-bar .searchform::after {
  content: var(--tw-content);
  right: 14px;
}
[dir="rtl"] #topnav .buy-button .search-bar .searchform::after {
  content: var(--tw-content);
  left: 14px;
}
#topnav .buy-button .search-bar .searchform input[type="text"] {
  display: block !important;
  height: 2.75rem !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  outline: transparent solid 2px !important;
  outline-offset: 2px !important;
}
[dir="ltr"] #topnav .buy-button .search-bar .searchform input[type="text"] {
  padding-right: 42px !important;
  padding-left: 0.75rem !important;
}
[dir="rtl"] #topnav .buy-button .search-bar .searchform input[type="text"] {
  padding-left: 42px !important;
  padding-right: 0.75rem !important;
}
#topnav .buy-button .search-bar .searchform input[type="submit"] {
  display: none;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle::after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle::after {
  content: var(--tw-content);
  display: none;
}
#topnav .navigation-menu {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
#topnav .navigation-menu > li {
  position: relative;
  margin: 0 10px;
  display: block;
}
[dir="ltr"] #topnav .navigation-menu > li {
  float: left;
}
[dir="rtl"] #topnav .navigation-menu > li {
  float: right;
}
#topnav .navigation-menu > li > a {
  display: block !important;
  background-color: transparent !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 13px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  line-height: 1.5rem !important;
  letter-spacing: 1px !important;
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
.dark #topnav .navigation-menu > li > a {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li > a:active,
#topnav .navigation-menu > li > a:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li.active > a,
#topnav .navigation-menu > li:hover > a,
.dark #topnav .navigation-menu > li.active > a,
.dark #topnav .navigation-menu > li:hover > a {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  white-space: nowrap !important;
  padding: 10px 1.25rem !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.04em !important;
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 2rem;
  display: inline-block;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 0.5px;
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
  padding: 2px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
[dir="ltr"] #topnav .navigation-menu .has-submenu .menu-arrow {
  right: 0;
}
[dir="rtl"] #topnav .navigation-menu .has-submenu .menu-arrow {
  left: 0;
}
.dark #topnav .navigation-menu .has-submenu .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  --tw-rotate: 225deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="ltr"] #topnav .menu-extras {
  float: right;
}
[dir="rtl"] #topnav .menu-extras {
  float: left;
}
#topnav.scroll {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark #topnav.scroll {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
#topnav.scroll .navigation-menu > li > a {
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
.dark #topnav.scroll .navigation-menu > li > a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
}
.dark #topnav.scroll .navigation-menu > li > .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav.scroll .navigation-menu > li.active > a,
#topnav.scroll .navigation-menu > li:hover > a {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li.active > .menu-arrow,
#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
.dark #topnav.scroll .navigation-menu > li.active > .menu-arrow,
.dark #topnav.scroll .navigation-menu > li:hover > .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark #topnav.nav-sticky {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
.dark #topnav.nav-sticky .navigation-menu.nav-light > li > a {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
.dark #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
.dark #topnav.nav-sticky .navigation-menu.nav-light > li.active > a,
.dark #topnav.nav-sticky .navigation-menu.nav-light > li:hover > a {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
}
.dark #topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav.nav-sticky.tagline-height {
  top: 0 !important;
}
#topnav.nav-sticky .buy-button .btn-icon-dark,
#topnav.nav-sticky .buy-button .login-btn-primary {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .btn-icon-light,
#topnav.nav-sticky .buy-button .login-btn-light {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}
.logo {
  padding: 0 6px;
  font-size: 24px;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: 1px;
}
[dir="ltr"] .logo {
  margin-right: 15px;
}
[dir="rtl"] .logo {
  margin-left: 15px;
}
@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1120px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 34px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 2rem;
  }
  #topnav .navigation-menu > li .submenu {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    visibility: hidden;
    position: absolute;
    top: 100%;
    z-index: 1000;
    margin-top: 10px;
    min-width: 180px;
    list-style-type: none;
    border-radius: 0.375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 15px 0;
    opacity: 0;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu {
    left: 0;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu {
    right: 0;
  }
  .dark #topnav .navigation-menu > li .submenu {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    --tw-shadow-color: #1f2937;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding: 10px 1.25rem;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  .dark #topnav .navigation-menu > li .submenu li a {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu li a:hover,
  .dark #topnav .navigation-menu > li .submenu li a:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    margin: 0;
    list-style-type: none;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu li ul {
    padding-left: 0;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu li ul {
    padding-right: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    position: fixed;
    top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu.megamenu {
    left: 50%;
    --tw-translate-x: -50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu.megamenu {
    right: 50%;
    --tw-translate-x: 50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    width: 20%;
    overflow: hidden;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    top: 0;
    margin-top: -1px;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    margin-left: 10px;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    right: 100%;
    margin-right: 10px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    top: 0;
    margin-top: -1px;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu > li .submenu {
    left: 101%;
    margin-left: 10px;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu > li .submenu {
    right: 101%;
    margin-right: 10px;
  }
  #topnav .navigation-menu > li > a {
    min-height: 62px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow,
  .dark #topnav .navigation-menu > li:hover > .menu-arrow {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
  }
  #topnav .navigation-menu > li.active > a,
  #topnav .navigation-menu > li:hover > a,
  .dark #topnav .navigation-menu > li.active > a,
  .dark #topnav .navigation-menu > li:hover > a {
    --tw-text-opacity: 1 !important;
    color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
  }
  [dir="ltr"] #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  [dir="ltr"] #topnav .navigation-menu > li.last-elements .submenu::before {
    left: auto;
    content: var(--tw-content);
    right: 10px;
  }
  [dir="rtl"] #topnav .navigation-menu > li.last-elements .submenu {
    right: auto;
    left: 0;
  }
  [dir="rtl"] #topnav .navigation-menu > li.last-elements .submenu::before {
    right: auto;
    content: var(--tw-content);
    left: 10px;
  }
  [dir="ltr"]
    #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px;
  }
  [dir="rtl"]
    #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    right: auto;
    left: 100%;
    margin-right: 0;
    margin-left: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgb(255 255 255 / 0.5) !important;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    --tw-border-opacity: 1 !important;
    border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgb(255 255 255 / 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    --tw-border-opacity: 1 !important;
    border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  }
  [dir="ltr"] #topnav .buy-button {
    margin-left: 15px;
    padding-left: 15px;
  }
  [dir="rtl"] #topnav .buy-button {
    margin-right: 15px;
    padding-right: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 991px) {
  #topnav {
    min-height: 74px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .dark #topnav {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    --tw-shadow-color: #1f2937;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  #topnav .navigation-menu,
  #topnav .navigation-menu > li {
    float: none !important;
  }
  #topnav .navigation-menu > li .submenu {
    margin: 0;
    display: none;
    list-style-type: none;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu {
    padding-left: 1.25rem;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu {
    padding-right: 1.25rem;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    position: relative !important;
    display: block !important;
    padding: 7px 15px !important;
    font-size: 11px !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: 0.04em !important;
    --tw-text-opacity: 1 !important;
    color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
  }
  .dark #topnav .navigation-menu > li .submenu li a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 7px 15px;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style-type: none;
  }
  [dir="ltr"] #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    padding-left: 0;
  }
  [dir="rtl"] #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    padding-right: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    position: relative;
    display: block;
    padding: 10px 15px;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style-type: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li > a {
    padding: 10px 20px;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li > a::after {
    content: var(--tw-content);
    position: absolute;
  }
  [dir="ltr"] #topnav .navigation-menu > li > a::after {
    content: var(--tw-content);
    right: 15px;
  }
  [dir="rtl"] #topnav .navigation-menu > li > a::after {
    content: var(--tw-content);
    left: 15px;
  }
  .dark #topnav .navigation-menu > li > a {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a,
  #topnav .navigation-menu > li > a:hover {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
  }
  #topnav .menu-extras .menu-item {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  .dark #topnav .menu-extras .menu-item {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  [dir="ltr"] #topnav .navbar-header {
    float: left;
  }
  [dir="rtl"] #topnav .navbar-header {
    float: right;
  }
  #topnav .buy-button .btn-icon-dark,
  #topnav .buy-button .login-btn-primary {
    display: inline-block !important;
  }
  #topnav .buy-button .btn-icon-light,
  #topnav .buy-button .login-btn-light {
    display: none;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    position: absolute;
    top: 0.75rem;
    --tw-rotate: 45deg;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  [dir="ltr"] #topnav .has-submenu .submenu .submenu-arrow {
    right: 1.25rem;
  }
  [dir="rtl"] #topnav .has-submenu .submenu .submenu-arrow {
    left: 1.25rem;
  }
  #topnav .has-submenu.active a {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
  }
  #navigation {
    position: absolute;
    top: 74px;
    display: none;
    height: auto;
    width: 100%;
    overflow: auto;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  [dir="ltr"] #navigation {
    left: 0;
  }
  [dir="rtl"] #navigation {
    right: 0;
  }
  .dark #navigation {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    margin-top: 0;
    opacity: 1;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu,
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu
    > li:hover
    > .submenu {
    visibility: visible;
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
  }
  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    margin: 0 10px !important;
    display: block !important;
    padding: 10px 1.25rem !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    -webkit-transform: translate3d(0, -54px, 0) !important;
    transform: translate3d(0, -54px, 0) !important;
  }
}
.tagline {
  position: absolute;
  z-index: 99;
  width: 100%;
  padding: 13px 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }
  .tagline-height {
    top: 0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}
.sidebar-nav li.active a,
.sidebar-nav > .navbar-item.active .navbar-link,
.sidebar-nav > .navbar-item:hover .navbar-link {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
.sidebar-nav li.account-menu.active .navbar-link,
.sidebar-nav li.account-menu:hover .navbar-link {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
.dark .sidebar-nav li.account-menu.active .navbar-link,
.dark .sidebar-nav li.account-menu:hover .navbar-link {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.footer .foot-subscribe input::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input:-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.container {
  position: relative;
}
.cookie-popup-not-accepted {
  display: block;
  -webkit-animation: 0.5s forwards cookie-popup-in;
  animation: 0.5s forwards cookie-popup-in;
}
.cookie-popup-accepted {
  display: none;
}
@-webkit-keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
.shape > svg {
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.shape.marketing-hero {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
#preloader {
  background-image: linear-gradient(45deg, #fff, #fff);
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#preloader #status .spinner {
  position: relative;
  margin: 100px auto;
  height: 2.5rem;
  width: 2.5rem;
}
#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  background-color: rgb(79 70 229 / 0.6);
  -webkit-animation: 2s ease-in-out infinite sk-bounce;
  animation: 2s ease-in-out infinite sk-bounce;
}
[dir="ltr"] #preloader #status .spinner .double-bounce1,
[dir="ltr"] #preloader #status .spinner .double-bounce2 {
  left: 0;
}
[dir="rtl"] #preloader #status .spinner .double-bounce1,
[dir="rtl"] #preloader #status .spinner .double-bounce2 {
  right: 0;
}
#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.label .ball {
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  --tw-translate-x: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.checkbox:checked + .label .ball {
  --tw-translate-x: 1.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.mover {
  -webkit-animation: 1.5s infinite alternate mover;
  animation: 1.5s infinite alternate mover;
}
@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
.background-effect .circles {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.background-effect .circles li {
  position: absolute;
  bottom: -150px;
  display: block;
  background-color: rgb(79 70 229 / 0.3);
  -webkit-animation: 25s linear infinite animate;
  animation: 25s linear infinite animate;
}
.background-effect .circles li.brand-img:first-child,
.background-effect .circles li.brand-img:nth-child(10),
.background-effect .circles li.brand-img:nth-child(2),
.background-effect .circles li.brand-img:nth-child(3),
.background-effect .circles li.brand-img:nth-child(4),
.background-effect .circles li.brand-img:nth-child(5),
.background-effect .circles li.brand-img:nth-child(6),
.background-effect .circles li.brand-img:nth-child(7),
.background-effect .circles li.brand-img:nth-child(8),
.background-effect .circles li.brand-img:nth-child(9) {
  background-color: transparent;
}
.background-effect .circles li:first-child,
.background-effect .circles li:nth-child(10),
.background-effect .circles li:nth-child(2),
.background-effect .circles li:nth-child(3),
.background-effect .circles li:nth-child(4),
.background-effect .circles li:nth-child(5),
.background-effect .circles li:nth-child(6),
.background-effect .circles li:nth-child(7),
.background-effect .circles li:nth-child(8),
.background-effect .circles li:nth-child(9) {
  height: 3rem;
  width: 3rem;
}
.background-effect .circles li:first-child {
  left: 25%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(2) {
  left: 10%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 12s;
  animation-duration: 12s;
}
.background-effect .circles li:nth-child(3) {
  left: 70%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.background-effect .circles li:nth-child(4) {
  left: 40%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-duration: 18s;
  animation-duration: 18s;
}
.background-effect .circles li:nth-child(5) {
  left: 65%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.background-effect .circles li:nth-child(6) {
  left: 75%;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.background-effect .circles li:nth-child(7) {
  left: 35%;
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.background-effect .circles li:nth-child(8) {
  left: 50%;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 45s;
  animation-duration: 45s;
}
.background-effect .circles li:nth-child(9) {
  left: 20%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 35s;
  animation-duration: 35s;
}
.background-effect .circles li:nth-child(10) {
  left: 85%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-duration: 11s;
  animation-duration: 11s;
}
@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    opacity: 1;
    border-radius: 10px;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    opacity: 1;
    border-radius: 10px;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
.studio-wrapper::before {
  position: absolute;
  bottom: 14rem;
  height: 30rem;
  width: 60rem;
  border-radius: 18rem;
  background-color: rgb(79 70 229 / 0.05);
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .studio-wrapper::before {
  left: 50rem;
  content: var(--tw-content);
  --tw-rotate: 135deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="rtl"] .studio-wrapper::before {
  right: 50rem;
  content: var(--tw-content);
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.dark .studio-wrapper::before {
  content: var(--tw-content);
  background-color: rgb(79 70 229 / 0.1);
}
@media (min-width: 1024px) and (max-width: 1140px) {
  .studio-wrapper::before {
    bottom: 10rem;
    height: 20rem;
    content: var(--tw-content);
    width: 40rem;
  }
  [dir="ltr"] .studio-wrapper::before {
    content: var(--tw-content);
    left: 30rem;
  }
  [dir="rtl"] .studio-wrapper::before {
    content: var(--tw-content);
    right: 30rem;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    top: 1rem;
  }
  [dir="ltr"] #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 0.5rem;
  }
  [dir="rtl"] #topnav .navigation-menu .has-submenu .menu-arrow {
    left: 0.5rem;
  }
  [dir="ltr"] .studio-wrapper::before {
    content: var(--tw-content);
    left: 12rem;
  }
  [dir="rtl"] .studio-wrapper::before {
    content: var(--tw-content);
    right: 12rem;
  }
}
@media (max-width: 767px) {
  [dir="ltr"] .studio-wrapper::before {
    content: var(--tw-content);
    left: 0;
  }
  [dir="rtl"] .studio-wrapper::before {
    content: var(--tw-content);
    right: 0;
  }
}
.startup-wrapper::before {
  position: absolute;
  bottom: 10rem;
  height: 35rem;
  width: 90rem;
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .startup-wrapper::before {
  left: 45rem;
  content: var(--tw-content);
  --tw-rotate: 115deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="rtl"] .startup-wrapper::before {
  right: 45rem;
  content: var(--tw-content);
  --tw-rotate: 65deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.startup-wrapper::after {
  position: absolute;
  bottom: 24rem;
  z-index: -1;
  height: 10rem;
  width: 75rem;
  background-color: rgb(79 70 229 / 0.1);
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .startup-wrapper::after {
  left: 30rem;
  content: var(--tw-content);
  --tw-rotate: 115deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="rtl"] .startup-wrapper::after {
  right: 30rem;
  content: var(--tw-content);
  --tw-rotate: 65deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 1024px) and (max-width: 1140px) {
  .startup-wrapper::before {
    height: 30rem;
    content: var(--tw-content);
    width: 75rem;
  }
  .startup-wrapper::after {
    bottom: 20rem;
    height: 15rem;
    content: var(--tw-content);
    width: 75rem;
  }
  [dir="ltr"] .startup-wrapper::before {
    content: var(--tw-content);
    left: 10rem;
  }
  [dir="ltr"] .startup-wrapper::after {
    content: var(--tw-content);
    left: 0;
  }
  [dir="rtl"] .startup-wrapper::before {
    content: var(--tw-content);
    right: 10rem;
  }
  [dir="rtl"] .startup-wrapper::after {
    content: var(--tw-content);
    right: 0;
  }
}
@media (max-width: 768px) {
  [dir="ltr"] .startup-wrapper::before {
    content: var(--tw-content);
    left: 10rem;
  }
  [dir="ltr"] .startup-wrapper::after {
    content: var(--tw-content);
    left: 0;
  }
  [dir="rtl"] .startup-wrapper::before {
    content: var(--tw-content);
    right: 10rem;
  }
  [dir="rtl"] .startup-wrapper::after {
    content: var(--tw-content);
    right: 0;
  }
}
@media (max-width: 767px) {
  [dir="ltr"] .startup-wrapper::before {
    content: var(--tw-content);
    left: 0;
  }
  [dir="ltr"] .startup-wrapper::after {
    content: var(--tw-content);
    left: -10rem;
  }
  [dir="rtl"] .startup-wrapper::before {
    content: var(--tw-content);
    right: 0;
  }
  [dir="rtl"] .startup-wrapper::after {
    content: var(--tw-content);
    right: -10rem;
  }
}
.image-wrap {
  -webkit-animation: 100s linear infinite alternate ppb_kenburns;
  animation: 100s linear infinite alternate ppb_kenburns;
}
@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
@keyframes move {
  0% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
@-webkit-keyframes ppb_kenburns {
  0%,
  100% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
  25%,
  75% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale(1.3) translate(10%, 10%);
    transform: scale(1.3) translate(10%, 10%);
  }
}
@keyframes ppb_kenburns {
  0%,
  100% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
  25%,
  75% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale(1.3) translate(10%, 10%);
    transform: scale(1.3) translate(10%, 10%);
  }
}
.home-wrapper::before {
  position: absolute;
  top: -30%;
  z-index: 1;
  height: 65rem;
  width: 140rem;
  background-color: rgb(79 70 229 / 0.05);
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .home-wrapper::before {
  left: -20rem;
  right: 0;
  content: var(--tw-content);
  --tw-rotate: -12deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
[dir="rtl"] .home-wrapper::before {
  right: -20rem;
  left: 0;
  content: var(--tw-content);
  --tw-rotate: 12deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.dark .home-wrapper::before {
  content: var(--tw-content);
  background-color: rgb(79 70 229 / 0.1);
}
@media (min-width: 769px) and (max-width: 1024px) {
  .home-wrapper::before {
    content: var(--tw-content);
    top: -50%;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .home-wrapper::before {
    content: var(--tw-content);
    top: -80%;
  }
}
#token-sale .count-number .count-head {
  position: relative;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1px;
}
@media (min-width: 768px) {
  #token-sale .count-number .count-head {
    margin-top: -1.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
#countdown .count-down .count-number {
  height: 130px;
  width: 130px;
  border-radius: 9999px;
  background-color: rgb(255 255 255 / 0.1);
  font-size: 40px;
  line-height: 110px;
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
#countdown .count-down .count-head {
  position: relative;
  display: block;
  --tw-translate-y: -25px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#maintenance .indicator {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.container-filter li.active,
.container-filter li:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}
.dark .container-filter li.active,
.dark .container-filter li:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.container-filter-box li.active,
.container-filter-box li:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
.container-filter-border-bottom li.active,
.container-filter-border-bottom li:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}
.container-filter-white li.active,
.container-filter-white li:hover,
.dark .container-filter-border-bottom li.active,
.dark .container-filter-border-bottom li:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.container-creative li::after {
  position: absolute;
  bottom: 5px;
  height: 0;
  width: 0;
  border-radius: 0.375rem;
  background-color: rgb(79 70 229 / 0.4);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --tw-content: "";
  content: var(--tw-content);
}
[dir="ltr"] .container-creative li::after {
  right: 0;
  content: var(--tw-content);
  left: 0;
}
[dir="rtl"] .container-creative li::after {
  left: 0;
  content: var(--tw-content);
  right: 0;
}
.container-creative li.active,
.container-creative li:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
.container-creative li.active::after,
.container-creative li:hover::after {
  top: 60% !important;
  height: 5px !important;
  content: var(--tw-content) !important;
  width: 100% !important;
}
[dir="ltr"] .container-creative li.active::after,
[dir="ltr"] .container-creative li:hover::after {
  right: auto !important;
  content: var(--tw-content) !important;
  left: 0 !important;
}
[dir="rtl"] .container-creative li.active::after,
[dir="rtl"] .container-creative li:hover::after {
  left: auto !important;
  content: var(--tw-content) !important;
  right: 0 !important;
}
.dark .container-creative li.active,
.dark .container-creative li:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.tobii > button.tobii__close svg,
.tobii > button.tobii__next svg,
.tobii > button.tobii__prev svg {
  height: 2.25rem;
  width: auto;
}
.tobii__counter {
  font-size: 1rem;
  line-height: 1.5rem;
}
.tobii-zoom {
  display: block;
}
.tobii-zoom__icon {
  display: none;
}
#grid {
  padding: 0;
}
@media (max-width: 640px) {
  [data-type] iframe,
  [data-type] video {
    width: 500px;
    height: 300px;
  }
}
@media (max-width: 425px) {
  .home-wrapper::before {
    content: var(--tw-content);
    top: -90%;
  }
  [data-type] iframe,
  [data-type] video {
    width: 360px;
    height: 260px;
  }
}
@media (max-width: 375px) {
  [data-type] iframe,
  [data-type] video {
    width: 100%;
    height: auto;
  }
}
.form-input {
  height: 2.5rem;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: inherit;
  background-color: transparent;
  padding: 0.5rem 0.75rem;
  outline: transparent solid 2px;
  outline-offset: 2px;
}
.dark .form-input {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}
.form-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.error {
  margin: 0.5rem 0;
  display: none;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
#ajaxsuccess {
  clear: both;
  margin: 0.5rem 0;
  display: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
}
#success_page,
.error_message {
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  padding: 0.625rem;
  text-align: center;
}
.error_message {
  background-color: rgb(220 38 38 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.contact-loader {
  display: none;
}
#success_page {
  background-color: rgb(5 150 105 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}
.swiper-slider-hero .swiper-container,
.swiper-slider-hero .swiper-slide .slide-inner {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.swiper-slider-hero .swiper-container .swiper-button-next,
.swiper-slider-hero .swiper-container .swiper-button-prev {
  margin-top: -30px;
  height: 35px;
  width: 35px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255 255 255 / 0.5);
  background-color: transparent;
  background-image: none;
  line-height: 35px;
}
.swiper-slider-hero .swiper-container .swiper-button-next:before,
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-family: "Material Design Icons";
}
.swiper-slider-hero .swiper-container .swiper-button-next:hover,
.swiper-slider-hero .swiper-container .swiper-button-prev:hover {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}
.swiper-slider-hero .swiper-pagination-bullet {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  bottom: 45px;
}
.swiper-slider-hero
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 13px;
}
.client-testi {
  cursor: e-resize;
}
.client-testi .content::before {
  position: absolute;
  top: 2rem;
  left: 0;
  margin-left: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: 0;
  transform-origin: 0;
  --tw-rotate: 135deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 8px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-left-color: transparent;
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-content: "";
  content: var(--tw-content);
}
.dark .client-testi .content::before {
  border-right-color: rgb(15 23 42 / var(--tw-border-opacity));
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity));
}
.customer-testi {
  cursor: e-resize;
}
.customer-testi .content::before {
  position: absolute;
  left: 50%;
  bottom: -4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 8px;
  border-top-color: transparent;
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-left-color: transparent;
  --tw-shadow: 2px 2px 2px -1px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 2px 2px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-content: "";
  content: var(--tw-content);
}
.dark .customer-testi .content::before {
  border-right-color: rgb(15 23 42 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity));
  content: var(--tw-content);
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
.customer-testi .content:before {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.tns-nav {
  margin-top: 0.75rem;
  text-align: center;
}
.tns-nav button {
  margin: 0.25rem;
  border-radius: 3px;
  border-width: 0;
  background-color: rgb(79 70 229 / 0.3);
  padding: 5px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.tns-nav button.tns-nav-active {
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
.tns-controls button[data-controls="next"],
.tns-controls button[data-controls="prev"] {
  position: absolute;
  top: 50%;
  z-index: 10;
  height: 2rem;
  width: 2rem;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(60 72 88 / var(--tw-text-opacity));
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.dark .tns-controls button[data-controls="next"],
.dark .tns-controls button[data-controls="prev"] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}
.tns-controls button[data-controls="next"]:hover,
.tns-controls button[data-controls="prev"]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tns-controls button[data-controls="prev"] {
  left: 0;
}
.tns-controls button[data-controls="next"] {
  right: 0;
}
@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-360px * 6));
    transform: translateX(calc(-360px * 6));
  }
}
@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-360px * 6));
    transform: translateX(calc(-360px * 6));
  }
}
.slider:after {
  -webkit-transform: rotateZ(360deg);
  transform: rotateZ(360deg);
}
.slider .slide-track {
  -webkit-animation: 120s linear infinite scroll;
  animation: 120s linear infinite scroll;
  width: calc(360px * 20);
}
html[dir="rtl"] .slider,
html[dir="rtl"] .slider2,
html[dir="rtl"] .swiper-slider-hero,
html[dir="rtl"] .tns-inner,
html[dir="rtl"] .tns-nav {
  direction: ltr !important;
}
html[dir="rtl"] .tns-visually-hidden {
  left: 0 !important;
  visibility: hidden;
}
.filter-search-form .icons {
  position: absolute;
  top: 48%;
  z-index: 1;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: 20px;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
[dir="ltr"] .filter-search-form .icons {
  left: 0.75rem;
}
[dir="rtl"] .filter-search-form .icons {
  right: 0.75rem;
}
.registration-form {
  position: relative;
}
.registration-form .choices[data-type*="select-one"] .choices__inner,
.registration-form .form-input,
.registration-form .submit-btn {
  border-radius: 0.5rem;
}
@media (min-width: 992px) {
  .filter-border::before {
    position: absolute;
    top: 10px;
    z-index: 1;
    height: 2.5rem;
    border-radius: 0.375rem;
    border-right-width: 1px;
    border-color: inherit;
    outline-width: 0;
    --tw-content: "";
    content: var(--tw-content);
  }
  [dir="ltr"] .filter-border::before {
    content: var(--tw-content);
    right: 0;
  }
  [dir="rtl"] .filter-border::before {
    content: var(--tw-content);
    left: 0;
  }
  .dark .filter-border::before {
    content: var(--tw-content);
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  .registration-form {
    position: relative;
  }
  .registration-form .submit-btn {
    border-radius: 0 0 0.125rem 0.125rem;
  }
  [dir="ltr"] .registration-form .submit-btn {
    border-radius: 0 0.125rem 0.125rem 0;
  }
  [dir="rtl"] .registration-form .submit-btn {
    border-radius: 0.125rem 0 0 0.125rem;
  }
  .registration-form .form-input {
    border-radius: 0.125rem 0.125rem 0 0;
    outline-width: 0;
  }
  [dir="ltr"] .registration-form .form-input {
    border-radius: 0.125rem 0 0 0.125rem;
  }
  [dir="rtl"] .registration-form .form-input {
    border-radius: 0 0.125rem 0.125rem 0;
  }
  .registration-form .choices[data-type*="select-one"] .choices__inner {
    border-radius: 0;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  height: 60px;
  border-radius: 0;
  border-width: 0;
  padding-top: 13px;
  padding-bottom: 15px;
  font-size: 15px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
[dir="ltr"] .filter-input-box,
[dir="ltr"] .filter-input-box.form-select,
[dir="ltr"] .filter-search-form .choices__inner {
  padding-right: 6px;
  padding-left: 45px;
}
[dir="rtl"] .filter-input-box,
[dir="rtl"] .filter-input-box.form-select,
[dir="rtl"] .filter-search-form .choices__inner {
  padding-left: 6px;
  padding-right: 45px;
}
.choices__inner {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .choices__inner {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
.choices[data-type*="select-one"] .choices__input,
.choices__list--dropdown {
  z-index: 999;
  border-radius: 5px;
  border-width: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.dark .choices[data-type*="select-one"] .choices__input,
.dark .choices__list--dropdown {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
.dark .choices__list--dropdown .choices__item--selectable.is-highlighted {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.choices__list,
.choices__list--dropdown .choices__item--selectable {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.choices__list--dropdown,
.choices__list[aria-expanded] {
  z-index: 2;
  border-width: 0;
}
.choices[data-type*="select-one"]::after {
  top: 1.25rem;
  border-width: 0;
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
[dir="ltr"] .choices[data-type*="select-one"]::after {
  right: 1.75rem;
  content: var(--tw-content);
  left: auto;
}
[dir="rtl"] .choices[data-type*="select-one"]::after {
  left: 1.75rem;
  content: var(--tw-content);
  right: auto;
}
.dark .choices[data-type*="select-one"]::after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.choices[data-type*="select-one"]:after {
  content: "󰅀" !important;
  font-family: "Material Design Icons";
}
.qs-datepicker-container {
  border-color: inherit;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.dark .qs-datepicker-container {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.qs-datepicker-container .qs-controls {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
.dark .qs-datepicker-container .qs-controls {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
.qs-datepicker-container .qs-controls .qs-month-year {
  font-size: 15px;
  font-weight: 600;
}
.dark .qs-datepicker-container .qs-controls .qs-month-year {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-radius: 0.375rem;
  border-bottom-width: 1px;
  border-color: inherit;
  outline-color: inherit;
}
.qs-squares .qs-square {
  height: 34px;
  width: 34px;
  font-size: 15px;
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.qs-squares .qs-square.qs-current,
.qs-squares
  .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(
    .qs-active
  ):hover {
  text-decoration: none;
  border-radius: 30px;
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.qs-squares .qs-day {
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}
.dark .qs-squares .qs-day {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.before\:absolute::before {
  content: var(--tw-content) !important;
  position: absolute !important;
}
.before\:-inset-1::before {
  content: var(--tw-content) !important;
  top: -0.25rem !important;
  right: -0.25rem !important;
  bottom: -0.25rem !important;
  left: -0.25rem !important;
}
.before\:-bottom-1::before {
  content: var(--tw-content) !important;
  bottom: -0.25rem !important;
}
.before\:top-0::before {
  content: var(--tw-content) !important;
  top: 0 !important;
}
.before\:left-0::before {
  content: var(--tw-content) !important;
  left: 0 !important;
}
.before\:-bottom-40::before {
  content: var(--tw-content) !important;
  bottom: -10rem !important;
}
.before\:z-2::before {
  content: var(--tw-content) !important;
  z-index: 2 !important;
}
.before\:-z-1::before {
  content: var(--tw-content) !important;
  z-index: -1 !important;
}
.before\:z-10::before {
  content: var(--tw-content) !important;
  z-index: 10 !important;
}
.before\:mx-auto::before {
  content: var(--tw-content) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.before\:block::before {
  content: var(--tw-content) !important;
  display: block !important;
}
.before\:h-\[30rem\]::before {
  content: var(--tw-content) !important;
  height: 30rem !important;
}
.before\:h-7::before {
  content: var(--tw-content) !important;
  height: 1.75rem !important;
}
.before\:w-28::before {
  content: var(--tw-content) !important;
  width: 7rem !important;
}
.before\:w-\[30rem\]::before {
  content: var(--tw-content) !important;
  width: 30rem !important;
}
.before\:w-7::before {
  content: var(--tw-content) !important;
  width: 1.75rem !important;
}
.before\:-skew-y-3::before {
  content: var(--tw-content) !important;
  --tw-skew-y: -3deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.before\:rounded-full::before {
  content: var(--tw-content) !important;
  border-radius: 9999px !important;
}
.before\:border-\[6px\]::before {
  content: var(--tw-content) !important;
  border-width: 6px !important;
}
.before\:border-2::before {
  content: var(--tw-content) !important;
  border-width: 2px !important;
}
.before\:border-dashed::before {
  content: var(--tw-content) !important;
  border-style: dashed !important;
}
.before\:border-white::before {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}
.before\:border-gray-200::before {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.before\:bg-indigo-600::before {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.before\:bg-white::before {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.before\:content-\[\'\'\]::before {
  --tw-content: "" !important;
  content: var(--tw-content) !important;
}
.after\:absolute::after {
  content: var(--tw-content) !important;
  position: absolute !important;
}
.after\:-bottom-\[80px\]::after {
  content: var(--tw-content) !important;
  bottom: -80px !important;
}
.after\:bottom-0::after {
  content: var(--tw-content) !important;
  bottom: 0 !important;
}
.after\:bottom-3::after {
  content: var(--tw-content) !important;
  bottom: 0.75rem !important;
}
.after\:top-0::after {
  content: var(--tw-content) !important;
  top: 0 !important;
}
.after\:bottom-1\/2::after {
  content: var(--tw-content) !important;
  bottom: 50% !important;
}
.after\:-bottom-20::after {
  content: var(--tw-content) !important;
  bottom: -5rem !important;
}
.after\:top-16::after {
  content: var(--tw-content) !important;
  top: 4rem !important;
}
.after\:top-10::after {
  content: var(--tw-content) !important;
  top: 2.5rem !important;
}
.after\:right-0::after {
  content: var(--tw-content) !important;
  right: 0 !important;
}
.after\:bottom-2::after {
  content: var(--tw-content) !important;
  bottom: 0.5rem !important;
}
.after\:top-2\/4::after {
  content: var(--tw-content) !important;
  top: 50% !important;
}
.after\:top-1\/4::after {
  content: var(--tw-content) !important;
  top: 25% !important;
}
.after\:bottom-1::after {
  content: var(--tw-content) !important;
  bottom: 0.25rem !important;
}
.after\:top-\[9px\]::after {
  content: var(--tw-content) !important;
  top: 9px !important;
}
.after\:top-5::after {
  content: var(--tw-content) !important;
  top: 1.25rem !important;
}
.after\:z-3::after {
  content: var(--tw-content) !important;
  z-index: 3 !important;
}
.after\:-z-0::after {
  content: var(--tw-content) !important;
  z-index: 0 !important;
}
.after\:-z-1::after {
  content: var(--tw-content) !important;
  z-index: -1 !important;
}
.after\:z-10::after {
  content: var(--tw-content) !important;
  z-index: 10 !important;
}
.after\:z-2::after {
  content: var(--tw-content) !important;
  z-index: 2 !important;
}
.after\:mx-auto::after {
  content: var(--tw-content) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.after\:h-20::after {
  content: var(--tw-content) !important;
  height: 5rem !important;
}
.after\:h-40::after {
  content: var(--tw-content) !important;
  height: 10rem !important;
}
.after\:h-2::after {
  content: var(--tw-content) !important;
  height: 0.5rem !important;
}
.after\:h-10::after {
  content: var(--tw-content) !important;
  height: 2.5rem !important;
}
.after\:h-\[30rem\]::after {
  content: var(--tw-content) !important;
  height: 30rem !important;
}
.after\:h-32::after {
  content: var(--tw-content) !important;
  height: 8rem !important;
}
.after\:h-\[512px\]::after {
  content: var(--tw-content) !important;
  height: 512px !important;
}
.after\:h-1\.5::after {
  content: var(--tw-content) !important;
  height: 0.375rem !important;
}
.after\:h-1::after {
  content: var(--tw-content) !important;
  height: 0.25rem !important;
}
.after\:h-full::after {
  content: var(--tw-content) !important;
  height: 100% !important;
}
.after\:h-2\.5::after {
  content: var(--tw-content) !important;
  height: 0.625rem !important;
}
.after\:w-20::after {
  content: var(--tw-content) !important;
  width: 5rem !important;
}
.after\:w-40::after {
  content: var(--tw-content) !important;
  width: 10rem !important;
}
.after\:w-auto::after {
  content: var(--tw-content) !important;
  width: auto !important;
}
.after\:w-10::after {
  content: var(--tw-content) !important;
  width: 2.5rem !important;
}
.after\:w-8::after {
  content: var(--tw-content) !important;
  width: 2rem !important;
}
.after\:w-\[75rem\]::after {
  content: var(--tw-content) !important;
  width: 75rem !important;
}
.after\:w-32::after {
  content: var(--tw-content) !important;
  width: 8rem !important;
}
.after\:w-\[512px\]::after {
  content: var(--tw-content) !important;
  width: 512px !important;
}
.after\:w-px::after {
  content: var(--tw-content) !important;
  width: 1px !important;
}
.after\:w-2\.5::after {
  content: var(--tw-content) !important;
  width: 0.625rem !important;
}
.after\:w-2::after {
  content: var(--tw-content) !important;
  width: 0.5rem !important;
}
.after\:translate-y-1\/2::after {
  content: var(--tw-content) !important;
  --tw-translate-y: 50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.after\:-rotate-6::after {
  content: var(--tw-content) !important;
  --tw-rotate: -6deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.after\:rotate-90::after {
  content: var(--tw-content) !important;
  --tw-rotate: 90deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@keyframes spin {
  to {
    content: var(--tw-content);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.after\:animate-\[spin_10s_linear_infinite\]::after {
  content: var(--tw-content) !important;
  -webkit-animation: 10s linear infinite spin !important;
  animation: 10s linear infinite spin !important;
}
.after\:rounded-none::after {
  content: var(--tw-content) !important;
  border-radius: 0 !important;
}
.after\:rounded-3xl::after {
  content: var(--tw-content) !important;
  border-radius: 1.5rem !important;
}
.after\:rounded-md::after {
  content: var(--tw-content) !important;
  border-radius: 0.375rem !important;
}
.after\:rounded-lg::after {
  content: var(--tw-content) !important;
  border-radius: 0.5rem !important;
}
.after\:rounded-\[20rem\]::after {
  content: var(--tw-content) !important;
  border-radius: 20rem !important;
}
.after\:rounded-full::after {
  content: var(--tw-content) !important;
  border-radius: 9999px !important;
}
.after\:border-2::after {
  content: var(--tw-content) !important;
  border-width: 2px !important;
}
.after\:border-b-0::after {
  content: var(--tw-content) !important;
  border-bottom-width: 0 !important;
}
.after\:border-dashed::after {
  content: var(--tw-content) !important;
  border-style: dashed !important;
}
.after\:border-indigo-600::after {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.after\:border-gray-200::after {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}
.after\:bg-indigo-600::after {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.after\:bg-indigo-600\/20::after {
  content: var(--tw-content) !important;
  background-color: rgb(79 70 229 / 0.2) !important;
}
.after\:bg-indigo-600\/30::after {
  content: var(--tw-content) !important;
  background-color: rgb(79 70 229 / 0.3) !important;
}
.after\:bg-indigo-600\/5::after {
  content: var(--tw-content) !important;
  background-color: rgb(79 70 229 / 0.05) !important;
}
.after\:bg-white::after {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.after\:bg-red-600\/5::after {
  content: var(--tw-content) !important;
  background-color: rgb(220 38 38 / 0.05) !important;
}
.after\:bg-gradient-to-l::after {
  content: var(--tw-content) !important;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to left,
    var(--tw-gradient-stops)
  ) !important;
}
.after\:from-green-600::after {
  content: var(--tw-content) !important;
  --tw-gradient-from: #16a34a !important;
  --tw-gradient-to: rgb(22 163 74 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.after\:to-indigo-600::after {
  content: var(--tw-content) !important;
  --tw-gradient-to: #4f46e5 !important;
}
.after\:shadow-2xl::after {
  content: var(--tw-content) !important;
  --tw-shadow: 0 25px 50px -12px rgb(60 72 88 / 0.25) !important;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.after\:shadow-indigo-600\/40::after {
  content: var(--tw-content) !important;
  --tw-shadow-color: rgb(79 70 229 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.after\:content-\[\'\'\]::after {
  --tw-content: "" !important;
  content: var(--tw-content) !important;
}
.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.5rem !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.hover\:scale-105:hover {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}
.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.hover\:border-green-700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}
.hover\:border-emerald-700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}
.hover\:border-slate-200:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}
.hover\:border-gray-100:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}
.hover\:border-emerald-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}
.hover\:border-red-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}
.hover\:border-sky-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}
.hover\:border-b-indigo-600:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-indigo-600\/5:hover {
  background-color: rgb(79 70 229 / 0.05) !important;
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-emerald-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-emerald-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity)) !important;
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}
.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
.hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.hover\:text-emerald-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-text-opacity)) !important;
}
.hover\:text-sky-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(2 132 199 / var(--tw-text-opacity)) !important;
}
.hover\:text-amber-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(217 119 6 / var(--tw-text-opacity)) !important;
}
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}
.hover\:text-slate-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}
.hover\:text-gray-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 25px -3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 10px 25px -3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover\:shadow-md:hover {
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2) !important;
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover\:shadow:hover {
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(60 72 88 / 0.1),
    0 8px 10px -6px rgb(60 72 88 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.hover\:shadow-indigo-600:hover {
  --tw-shadow-color: #4f46e5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.hover\:shadow-gray-100:hover {
  --tw-shadow-color: #f3f4f6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.focus\:text-red-600:focus {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.focus\:outline-none:focus {
  outline: transparent solid 2px !important;
  outline-offset: 2px !important;
}
.group:hover .group-hover\:visible {
  visibility: visible !important;
}
.group:hover .group-hover\:bottom-5 {
  bottom: 1.25rem !important;
}
.group:hover .group-hover\:bottom-6 {
  bottom: 1.5rem !important;
}
.group:hover .group-hover\:bottom-0 {
  bottom: 0 !important;
}
.group:hover .group-hover\:bottom-2 {
  bottom: 0.5rem !important;
}
.group:hover .group-hover\:mb-0 {
  margin-bottom: 0 !important;
}
.group:hover .group-hover\:block {
  display: block !important;
}
.group:hover .group-hover\:inline-block {
  display: inline-block !important;
}
.group:hover .group-hover\:origin-center {
  -webkit-transform-origin: center !important;
  transform-origin: center !important;
}
.group:hover .group-hover\:rotate-3 {
  --tw-rotate: 3deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.group:hover .group-hover\:scale-105 {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.group:hover .group-hover\:scale-100 {
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.group:hover .group-hover\:animate-\[spin_10s_linear_infinite\] {
  -webkit-animation: 10s linear infinite spin !important;
  animation: 10s linear infinite spin !important;
}
.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.group:hover .group-hover\:bg-dark {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(60 72 88 / var(--tw-bg-opacity)) !important;
}
.group:hover .group-hover\:bg-white\/90 {
  background-color: rgb(255 255 255 / 0.9) !important;
}
.group:hover .group-hover\:bg-slate-900\/70 {
  background-color: rgb(15 23 42 / 0.7) !important;
}
.group:hover .group-hover\:bg-gradient-to-b {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to bottom,
    var(--tw-gradient-stops)
  ) !important;
}
.group:hover .group-hover\:from-transparent {
  --tw-gradient-from: transparent !important;
  --tw-gradient-to: rgb(0 0 0 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.group:hover .group-hover\:to-black {
  --tw-gradient-to: #161c2d !important;
}
.group:hover .group-hover\:fill-white\/10 {
  fill: rgb(255 255 255 / 0.1) !important;
}
.group:hover .group-hover\:stroke-\[1\.5px\] {
  stroke-width: 1.5px !important;
}
.group:hover .group-hover\:p-\[10px\] {
  padding: 10px !important;
}
.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-emerald-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-sky-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 132 199 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-amber-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 158 11 / var(--tw-text-opacity)) !important;
}
.group:hover .group-hover\:text-white\/50 {
  color: rgb(255 255 255 / 0.5) !important;
}
.group:hover .group-hover\:text-white\/\[0\.04\] {
  color: rgb(255 255 255 / 0.04) !important;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1 !important;
}
.group:hover .group-hover\:opacity-70 {
  opacity: 0.7 !important;
}
.group:focus .group-focus\:outline-none {
  outline: transparent solid 2px !important;
  outline-offset: 2px !important;
}
[dir="ltr"] .ltr\:right-3 {
  right: 0.75rem !important;
}
[dir="ltr"] .ltr\:right-5 {
  right: 1.25rem !important;
}
[dir="ltr"] .ltr\:left-4 {
  left: 1rem !important;
}
[dir="ltr"] .ltr\:right-\[3px\] {
  right: 3px !important;
}
[dir="ltr"] .ltr\:left-3 {
  left: 0.75rem !important;
}
[dir="ltr"] .ltr\:left-0 {
  left: 0 !important;
}
[dir="ltr"] .ltr\:right-6 {
  right: 1.5rem !important;
}
[dir="ltr"] .ltr\:left-2\/4 {
  left: 50% !important;
}
[dir="ltr"] .ltr\:right-0 {
  right: 0 !important;
}
[dir="ltr"] .ltr\:left-1\/2 {
  left: 50% !important;
}
[dir="ltr"] .ltr\:-left-5 {
  left: -1.25rem !important;
}
[dir="ltr"] .ltr\:-right-5 {
  right: -1.25rem !important;
}
[dir="ltr"] .ltr\:-left-2 {
  left: -0.5rem !important;
}
[dir="ltr"] .ltr\:-right-2 {
  right: -0.5rem !important;
}
[dir="ltr"] .ltr\:left-8 {
  left: 2rem !important;
}
[dir="ltr"] .ltr\:left-1 {
  left: 0.25rem !important;
}
[dir="ltr"] .ltr\:-left-\[25\%\] {
  left: -25% !important;
}
[dir="ltr"] .ltr\:-right-\[1\%\] {
  right: -1% !important;
}
[dir="ltr"] .ltr\:right-4 {
  right: 1rem !important;
}
[dir="ltr"] .ltr\:-right-1 {
  right: -0.25rem !important;
}
[dir="ltr"] .ltr\:left-2 {
  left: 0.5rem !important;
}
[dir="ltr"] .ltr\:left-12 {
  left: 3rem !important;
}
[dir="ltr"] .ltr\:right-12 {
  right: 3rem !important;
}
[dir="ltr"] .ltr\:right-2 {
  right: 0.5rem !important;
}
[dir="ltr"] .ltr\:left-6 {
  left: 1.5rem !important;
}
[dir="ltr"] .ltr\:-right-11 {
  right: -2.75rem !important;
}
[dir="ltr"] .ltr\:left-5 {
  left: 1.25rem !important;
}
[dir="ltr"] .ltr\:float-left {
  float: left !important;
}
[dir="ltr"] .ltr\:mr-2 {
  margin-right: 0.5rem !important;
}
[dir="ltr"] .ltr\:ml-3 {
  margin-left: 0.75rem !important;
}
[dir="ltr"] .ltr\:ml-5 {
  margin-left: 1.25rem !important;
}
[dir="ltr"] .ltr\:ml-44 {
  margin-left: 11rem !important;
}
[dir="ltr"] .ltr\:ml-2 {
  margin-left: 0.5rem !important;
}
[dir="ltr"] .ltr\:mr-4 {
  margin-right: 1rem !important;
}
[dir="ltr"] .ltr\:ml-1 {
  margin-left: 0.25rem !important;
}
[dir="ltr"] .ltr\:mr-3 {
  margin-right: 0.75rem !important;
}
[dir="ltr"] .ltr\:ml-4 {
  margin-left: 1rem !important;
}
[dir="ltr"] .ltr\:ml-auto {
  margin-left: auto !important;
}
[dir="ltr"] .ltr\:mr-1 {
  margin-right: 0.25rem !important;
}
[dir="ltr"] .ltr\:mr-6 {
  margin-right: 1.5rem !important;
}
[dir="ltr"] .ltr\:ml-6 {
  margin-left: 1.5rem !important;
}
[dir="ltr"] .ltr\:ml-8 {
  margin-left: 2rem !important;
}
[dir="ltr"] .ltr\:-ml-\[10px\] {
  margin-left: -10px !important;
}
[dir="ltr"] .ltr\:block {
  display: block !important;
}
[dir="ltr"] .ltr\:hidden {
  display: none !important;
}
[dir="ltr"] .ltr\:-translate-x-1\/2,
[dir="ltr"] .ltr\:-translate-x-2\/4 {
  --tw-translate-x: -50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:rotate-12 {
  --tw-rotate: 12deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:rotate-\[15deg\] {
  --tw-rotate: 15deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:-rotate-\[15deg\] {
  --tw-rotate: -15deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:rotate-\[45deg\] {
  --tw-rotate: 45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
[dir="ltr"] .ltr\:rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
[dir="ltr"] .ltr\:rounded-tl-xl {
  border-top-left-radius: 0.75rem !important;
}
[dir="ltr"] .ltr\:rounded-br-xl {
  border-bottom-right-radius: 0.75rem !important;
}
[dir="ltr"] .ltr\:bg-\[url\(\'\.\.\/\.\.\/assets\/images\/it\/bg\.jpg\'\)\] {
  background-image: url("../images/it/bg.jpg") !important;
}
[dir="ltr"] .ltr\:bg-\[url\(\'\.\.\/\.\.\/assets\/images\/nft\/hero\.png\'\)\] {
  background-image: url("../images/nft/hero.png") !important;
}
[dir="ltr"] .ltr\:bg-gradient-to-r {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to right,
    var(--tw-gradient-stops)
  ) !important;
}
[dir="ltr"] .ltr\:pl-11 {
  padding-left: 2.75rem !important;
}
[dir="ltr"] .ltr\:pr-40 {
  padding-right: 10rem !important;
}
[dir="ltr"] .ltr\:pl-6 {
  padding-left: 1.5rem !important;
}
[dir="ltr"] .ltr\:pl-4 {
  padding-left: 1rem !important;
}
[dir="ltr"] .ltr\:pl-3 {
  padding-left: 0.75rem !important;
}
[dir="ltr"] .ltr\:pr-12 {
  padding-right: 3rem !important;
}
[dir="ltr"] .ltr\:pl-12 {
  padding-left: 3rem !important;
}
[dir="ltr"] .ltr\:pr-4 {
  padding-right: 1rem !important;
}
[dir="ltr"] .ltr\:pl-0 {
  padding-left: 0 !important;
}
[dir="ltr"] .ltr\:pl-1 {
  padding-left: 0.25rem !important;
}
[dir="ltr"] .ltr\:text-left {
  text-align: left !important;
}
[dir="ltr"] .ltr\:text-right {
  text-align: right !important;
}
[dir="ltr"] .ltr\:before\:left-16::before {
  content: var(--tw-content) !important;
  left: 4rem !important;
}
[dir="ltr"] .ltr\:before\:right-36::before {
  content: var(--tw-content) !important;
  right: 9rem !important;
}
[dir="ltr"] .ltr\:before\:left-0::before {
  content: var(--tw-content) !important;
  left: 0 !important;
}
[dir="ltr"] .ltr\:before\:-left-11::before {
  content: var(--tw-content) !important;
  left: -2.75rem !important;
}
[dir="ltr"] .ltr\:after\:left-\[60px\]::after {
  content: var(--tw-content) !important;
  left: 60px !important;
}
[dir="ltr"] .ltr\:after\:right-0::after {
  content: var(--tw-content) !important;
  right: 0 !important;
}
[dir="ltr"] .ltr\:after\:left-0::after {
  content: var(--tw-content) !important;
  left: 0 !important;
}
[dir="ltr"] .ltr\:after\:-left-24::after {
  content: var(--tw-content) !important;
  left: -6rem !important;
}
[dir="ltr"] .ltr\:after\:-right-5::after {
  content: var(--tw-content) !important;
  right: -1.25rem !important;
}
[dir="ltr"] .ltr\:after\:-left-9::after {
  content: var(--tw-content) !important;
  left: -2.25rem !important;
}
[dir="ltr"] .ltr\:after\:rotate-\[130deg\]::after {
  content: var(--tw-content) !important;
  --tw-rotate: 130deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="ltr"] .ltr\:after\:rounded-r-\[50px\]::after {
  content: var(--tw-content) !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
[dir="ltr"] .ltr\:after\:border-l-0::after {
  content: var(--tw-content) !important;
  border-left-width: 0 !important;
}
[dir="ltr"] .ltr\:after\:border-l-2::after {
  content: var(--tw-content) !important;
  border-left-width: 2px !important;
}
[dir="rtl"] .rtl\:left-3 {
  left: 0.75rem !important;
}
[dir="rtl"] .rtl\:left-5 {
  left: 1.25rem !important;
}
[dir="rtl"] .rtl\:right-4 {
  right: 1rem !important;
}
[dir="rtl"] .rtl\:left-\[3px\] {
  left: 3px !important;
}
[dir="rtl"] .rtl\:right-3 {
  right: 0.75rem !important;
}
[dir="rtl"] .rtl\:right-0 {
  right: 0 !important;
}
[dir="rtl"] .rtl\:left-6 {
  left: 1.5rem !important;
}
[dir="rtl"] .rtl\:right-2\/4 {
  right: 50% !important;
}
[dir="rtl"] .rtl\:left-0 {
  left: 0 !important;
}
[dir="rtl"] .rtl\:right-1\/2 {
  right: 50% !important;
}
[dir="rtl"] .rtl\:-right-5 {
  right: -1.25rem !important;
}
[dir="rtl"] .rtl\:-left-5 {
  left: -1.25rem !important;
}
[dir="rtl"] .rtl\:-right-2 {
  right: -0.5rem !important;
}
[dir="rtl"] .rtl\:-left-2 {
  left: -0.5rem !important;
}
[dir="rtl"] .rtl\:right-8 {
  right: 2rem !important;
}
[dir="rtl"] .rtl\:right-1 {
  right: 0.25rem !important;
}
[dir="rtl"] .rtl\:-right-\[25\%\] {
  right: -25% !important;
}
[dir="rtl"] .rtl\:-left-\[1\%\] {
  left: -1% !important;
}
[dir="rtl"] .rtl\:left-4 {
  left: 1rem !important;
}
[dir="rtl"] .rtl\:-left-1 {
  left: -0.25rem !important;
}
[dir="rtl"] .rtl\:right-2 {
  right: 0.5rem !important;
}
[dir="rtl"] .rtl\:right-12 {
  right: 3rem !important;
}
[dir="rtl"] .rtl\:left-12 {
  left: 3rem !important;
}
[dir="rtl"] .rtl\:left-2 {
  left: 0.5rem !important;
}
[dir="rtl"] .rtl\:right-6 {
  right: 1.5rem !important;
}
[dir="rtl"] .rtl\:-left-11 {
  left: -2.75rem !important;
}
[dir="rtl"] .rtl\:right-5 {
  right: 1.25rem !important;
}
[dir="rtl"] .rtl\:float-right {
  float: right !important;
}
[dir="rtl"] .rtl\:ml-2 {
  margin-left: 0.5rem !important;
}
[dir="rtl"] .rtl\:mr-3 {
  margin-right: 0.75rem !important;
}
[dir="rtl"] .rtl\:mr-5 {
  margin-right: 1.25rem !important;
}
[dir="rtl"] .rtl\:mr-44 {
  margin-right: 11rem !important;
}
[dir="rtl"] .rtl\:-mt-1 {
  margin-top: -0.25rem !important;
}
[dir="rtl"] .rtl\:mr-2 {
  margin-right: 0.5rem !important;
}
[dir="rtl"] .rtl\:ml-4 {
  margin-left: 1rem !important;
}
[dir="rtl"] .rtl\:mr-1 {
  margin-right: 0.25rem !important;
}
[dir="rtl"] .rtl\:ml-3 {
  margin-left: 0.75rem !important;
}
[dir="rtl"] .rtl\:mr-4 {
  margin-right: 1rem !important;
}
[dir="rtl"] .rtl\:mr-auto {
  margin-right: auto !important;
}
[dir="rtl"] .rtl\:ml-1 {
  margin-left: 0.25rem !important;
}
[dir="rtl"] .rtl\:ml-6 {
  margin-left: 1.5rem !important;
}
[dir="rtl"] .rtl\:mr-6 {
  margin-right: 1.5rem !important;
}
[dir="rtl"] .rtl\:mr-8 {
  margin-right: 2rem !important;
}
[dir="rtl"] .rtl\:-mr-\[10px\] {
  margin-right: -10px !important;
}
[dir="rtl"] .rtl\:block {
  display: block !important;
}
[dir="rtl"] .rtl\:hidden {
  display: none !important;
}
[dir="rtl"] .rtl\:translate-x-1\/2,
[dir="rtl"] .rtl\:translate-x-2\/4 {
  --tw-translate-x: 50% !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:rotate-180 {
  --tw-rotate: 180deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:-rotate-12 {
  --tw-rotate: -12deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:-rotate-\[15deg\] {
  --tw-rotate: -15deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:rotate-\[15deg\] {
  --tw-rotate: 15deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:-rotate-\[45deg\] {
  --tw-rotate: -45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
[dir="rtl"] .rtl\:rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
[dir="rtl"] .rtl\:rounded-tr-xl {
  border-top-right-radius: 0.75rem !important;
}
[dir="rtl"] .rtl\:rounded-bl-xl {
  border-bottom-left-radius: 0.75rem !important;
}
[dir="rtl"]
  .rtl\:bg-\[url\(\'\.\.\/\.\.\/assets\/images\/it\/bg-rtl\.jpg\'\)\] {
  background-image: url("../images/it/bg-rtl.jpg") !important;
}
[dir="rtl"]
  .rtl\:bg-\[url\(\'\.\.\/\.\.\/assets\/images\/nft\/hero-rtl\.png\'\)\] {
  background-image: url("../images/nft/hero-rtl.png") !important;
}
[dir="rtl"] .rtl\:bg-gradient-to-l {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(var(--tw-gradient-stops))
  ) !important;
  background-image: linear-gradient(
    to left,
    var(--tw-gradient-stops)
  ) !important;
}
[dir="rtl"] .rtl\:pr-11 {
  padding-right: 2.75rem !important;
}
[dir="rtl"] .rtl\:pl-40 {
  padding-left: 10rem !important;
}
[dir="rtl"] .rtl\:pr-6 {
  padding-right: 1.5rem !important;
}
[dir="rtl"] .rtl\:pr-4 {
  padding-right: 1rem !important;
}
[dir="rtl"] .rtl\:pr-3 {
  padding-right: 0.75rem !important;
}
[dir="rtl"] .rtl\:pl-12 {
  padding-left: 3rem !important;
}
[dir="rtl"] .rtl\:pr-12 {
  padding-right: 3rem !important;
}
[dir="rtl"] .rtl\:pr-0 {
  padding-right: 0 !important;
}
[dir="rtl"] .rtl\:pr-1 {
  padding-right: 0.25rem !important;
}
[dir="rtl"] .rtl\:text-left {
  text-align: left !important;
}
[dir="rtl"] .rtl\:text-right {
  text-align: right !important;
}
[dir="rtl"] .rtl\:before\:right-16::before {
  content: var(--tw-content) !important;
  right: 4rem !important;
}
[dir="rtl"] .rtl\:before\:left-36::before {
  content: var(--tw-content) !important;
  left: 9rem !important;
}
[dir="rtl"] .rtl\:before\:right-0::before {
  content: var(--tw-content) !important;
  right: 0 !important;
}
[dir="rtl"] .rtl\:before\:-right-11::before {
  content: var(--tw-content) !important;
  right: -2.75rem !important;
}
[dir="rtl"] .rtl\:after\:right-\[60px\]::after {
  content: var(--tw-content) !important;
  right: 60px !important;
}
[dir="rtl"] .rtl\:after\:left-0::after {
  content: var(--tw-content) !important;
  left: 0 !important;
}
[dir="rtl"] .rtl\:after\:right-0::after {
  content: var(--tw-content) !important;
  right: 0 !important;
}
[dir="rtl"] .rtl\:after\:-right-24::after {
  content: var(--tw-content) !important;
  right: -6rem !important;
}
[dir="rtl"] .rtl\:after\:-left-5::after {
  content: var(--tw-content) !important;
  left: -1.25rem !important;
}
[dir="rtl"] .rtl\:after\:-right-9::after {
  content: var(--tw-content) !important;
  right: -2.25rem !important;
}
[dir="rtl"] .rtl\:after\:-rotate-\[130deg\]::after {
  content: var(--tw-content) !important;
  --tw-rotate: -130deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
[dir="rtl"] .rtl\:after\:rounded-l-\[50px\]::after {
  content: var(--tw-content) !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
[dir="rtl"] .rtl\:after\:border-r-0::after {
  content: var(--tw-content) !important;
  border-right-width: 0 !important;
}
[dir="rtl"] .rtl\:after\:border-r-2::after {
  content: var(--tw-content) !important;
  border-right-width: 2px !important;
}
.dark .dark\:block {
  display: block !important;
}
.dark .dark\:inline-block {
  display: inline-block !important;
}
.dark .dark\:hidden {
  display: none !important;
}
.dark .dark\:border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}
.dark .dark\:border-gray-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}
.dark .dark\:border-slate-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}
.dark .dark\:border-slate-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}
.dark .dark\:bg-slate-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-slate-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-slate-900\/70 {
  background-color: rgb(15 23 42 / 0.7) !important;
}
.dark .dark\:bg-gray-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-slate-900\/60 {
  background-color: rgb(15 23 42 / 0.6) !important;
}
.dark .dark\:bg-indigo-600\/30 {
  background-color: rgb(79 70 229 / 0.3) !important;
}
.dark .dark\:bg-emerald-600\/30 {
  background-color: rgb(5 150 105 / 0.3) !important;
}
.dark .dark\:bg-red-600\/30 {
  background-color: rgb(220 38 38 / 0.3) !important;
}
.dark .dark\:bg-sky-600\/30 {
  background-color: rgb(2 132 199 / 0.3) !important;
}
.dark .dark\:bg-amber-600\/30 {
  background-color: rgb(217 119 6 / 0.3) !important;
}
.dark .dark\:bg-indigo-600\/10 {
  background-color: rgb(79 70 229 / 0.1) !important;
}
.dark .dark\:bg-indigo-600\/20 {
  background-color: rgb(79 70 229 / 0.2) !important;
}
.dark .dark\:bg-slate-900\/40 {
  background-color: rgb(15 23 42 / 0.4) !important;
}
.dark .dark\:bg-slate-800\/40 {
  background-color: rgb(30 41 59 / 0.4) !important;
}
.dark .dark\:bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-gray-800\/50 {
  background-color: rgb(31 41 55 / 0.5) !important;
}
.dark .dark\:bg-dark-footer {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(25 33 50 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:bg-opacity-80 {
  --tw-bg-opacity: 0.8 !important;
}
.dark
  .dark\:bg-\[url\(\'\.\.\/\.\.\/assets\/images\/job\/curve-shape-dark\.png\'\)\] {
  background-image: url("../images/job/curve-shape-dark.png") !important;
}
.dark .dark\:from-indigo-600\/40 {
  --tw-gradient-from: rgb(79 70 229 / 0.4) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.dark .dark\:from-slate-900 {
  --tw-gradient-from: #0f172a !important;
  --tw-gradient-to: rgb(15 23 42 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}
.dark .dark\:via-slate-900\/80 {
  --tw-gradient-to: rgb(15 23 42 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.8),
    var(--tw-gradient-to) !important;
}
.dark .dark\:via-slate-900 {
  --tw-gradient-to: rgb(15 23 42 / 0) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0f172a, var(--tw-gradient-to) !important;
}
.dark .dark\:text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-indigo-600\/20 {
  color: rgb(79 70 229 / 0.2) !important;
}
.dark .dark\:text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-slate-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 41 59 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-white\/5 {
  color: rgb(255 255 255 / 0.05) !important;
}
.dark .dark\:text-white\/75 {
  color: rgb(255 255 255 / 0.75) !important;
}
.dark .dark\:text-white\/\[0\.03\] {
  color: rgb(255 255 255 / 0.03) !important;
}
.dark .dark\:text-slate-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-white\/70 {
  color: rgb(255 255 255 / 0.7) !important;
}
.dark .dark\:text-white\/60 {
  color: rgb(255 255 255 / 0.6) !important;
}
.dark .dark\:text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-emerald-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(16 185 129 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}
.dark .dark\:text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-text-opacity)) !important;
}
.dark .dark\:opacity-50 {
  opacity: 0.5 !important;
}
.dark .dark\:shadow {
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15) !important;
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.dark .dark\:shadow-gray-800 {
  --tw-shadow-color: #1f2937 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.dark .dark\:shadow-gray-700 {
  --tw-shadow-color: #374151 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.dark .dark\:ring-slate-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity)) !important;
}
.dark .dark\:before\:border-slate-900::before {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}
.dark .dark\:before\:border-gray-700::before {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}
.dark .dark\:before\:bg-slate-900::before {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:after\:border-gray-700::after {
  content: var(--tw-content) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}
.dark .dark\:after\:bg-white::after {
  content: var(--tw-content) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:border-indigo-600:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.dark .dark\:hover\:border-gray-700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}
.dark .dark\:hover\:border-b-indigo-600:hover {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}
.dark .dark\:hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:bg-indigo-600\/5:hover {
  background-color: rgb(79 70 229 / 0.05) !important;
}
.dark .dark\:hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}
.dark .dark\:hover\:text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.dark .dark\:hover\:text-slate-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
.dark .dark\:hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(60 72 88 / 0.1),
    0 8px 10px -6px rgb(60 72 88 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.dark .dark\:hover\:shadow-gray-700:hover {
  --tw-shadow-color: #374151 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.dark .dark\:hover\:shadow-gray-800:hover {
  --tw-shadow-color: #1f2937 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}
.dark .group:hover .dark\:group-hover\:bg-slate-900\/90 {
  background-color: rgb(15 23 42 / 0.9) !important;
}
@media (min-width: 640px) {
  .sm\:-bottom-px {
    bottom: -1px !important;
  }
  .sm\:bottom-40 {
    bottom: 10rem !important;
  }
  .sm\:bottom-44 {
    bottom: 11rem !important;
  }
  .sm\:bottom-80 {
    bottom: 20rem !important;
  }
  .sm\:bottom-\[340px\] {
    bottom: 340px !important;
  }
  .sm\:left-0 {
    left: 0 !important;
  }
  .sm\:right-0 {
    right: 0 !important;
  }
  .sm\:-mt-\[220px\] {
    margin-top: -220px !important;
  }
  .sm\:-mt-\[200px\] {
    margin-top: -200px !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .sm\:h-2\/3 {
    height: 66.666667% !important;
  }
  .sm\:h-\[140px\] {
    height: 140px !important;
  }
  .sm\:w-1\/2 {
    width: 50% !important;
  }
  .sm\:w-2\/3 {
    width: 66.666667% !important;
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:before\:-bottom-80::before {
    content: var(--tw-content) !important;
    bottom: -20rem !important;
  }
  .sm\:before\:h-\[50rem\]::before {
    content: var(--tw-content) !important;
    height: 50rem !important;
  }
  .sm\:before\:w-\[50rem\]::before {
    content: var(--tw-content) !important;
    width: 50rem !important;
  }
}
@media (min-width: 768px) {
  .md\:right-0 {
    right: 0 !important;
  }
  .md\:bottom-16 {
    bottom: 4rem !important;
  }
  .md\:-left-24 {
    left: -6rem !important;
  }
  .md\:-right-24 {
    right: -6rem !important;
  }
  .md\:bottom-10 {
    bottom: 2.5rem !important;
  }
  .md\:-top-4 {
    top: -1rem !important;
  }
  .md\:-bottom-4 {
    bottom: -1rem !important;
  }
  .md\:bottom-36 {
    bottom: 9rem !important;
  }
  .md\:bottom-40 {
    bottom: 10rem !important;
  }
  .md\:bottom-\[285px\] {
    bottom: 285px !important;
  }
  .md\:bottom-\[305px\] {
    bottom: 305px !important;
  }
  .md\:order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .md\:order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }
  .md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }
  .md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }
  .md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }
  .md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }
  .md\:col-start-2 {
    grid-column-start: 2 !important;
  }
  .md\:m-6 {
    margin: 1.5rem !important;
  }
  .md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .md\:mt-24 {
    margin-top: 6rem !important;
  }
  .md\:mt-0 {
    margin-top: 0 !important;
  }
  .md\:mb-16 {
    margin-bottom: 4rem !important;
  }
  .md\:mr-10 {
    margin-right: 2.5rem !important;
  }
  .md\:mt-8 {
    margin-top: 2rem !important;
  }
  .md\:mb-0 {
    margin-bottom: 0 !important;
  }
  .md\:mb-20 {
    margin-bottom: 5rem !important;
  }
  .md\:mt-44 {
    margin-top: 11rem !important;
  }
  .md\:mt-6 {
    margin-top: 1.5rem !important;
  }
  .md\:mb-12 {
    margin-bottom: 3rem !important;
  }
  .md\:-mt-48 {
    margin-top: -12rem !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .md\:hidden {
    display: none !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-\[600px\] {
    height: 600px !important;
  }
  .md\:h-\[500px\] {
    height: 500px !important;
  }
  .md\:h-\[250px\] {
    height: 250px !important;
  }
  .md\:h-\[550px\] {
    height: 550px !important;
  }
  .md\:h-96 {
    height: 24rem !important;
  }
  .md\:w-1\/3 {
    width: 33.333333% !important;
  }
  .md\:w-1\/2 {
    width: 50% !important;
  }
  .md\:w-\[500px\] {
    width: 500px !important;
  }
  .md\:w-80 {
    width: 20rem !important;
  }
  .md\:w-\[360px\] {
    width: 360px !important;
  }
  .md\:w-1\/4 {
    width: 25% !important;
  }
  .md\:w-fit {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .md\:w-\[40\%\] {
    width: 40% !important;
  }
  .md\:w-\[60\%\] {
    width: 60% !important;
  }
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-3\/4 {
    width: 75% !important;
  }
  .md\:w-48 {
    width: 12rem !important;
  }
  .md\:w-2\/3 {
    width: 66.666667% !important;
  }
  .md\:w-\[550px\] {
    width: 550px !important;
  }
  .md\:w-72 {
    width: 18rem !important;
  }
  .md\:w-\[350px\] {
    width: 350px !important;
  }
  .md\:w-2\/5 {
    width: 40% !important;
  }
  .md\:w-3\/5 {
    width: 60% !important;
  }
  .md\:w-56 {
    width: 14rem !important;
  }
  .md\:max-w-\[300px\] {
    max-width: 300px !important;
  }
  .md\:max-w-xs {
    max-width: 20rem !important;
  }
  .md\:max-w-md {
    max-width: 28rem !important;
  }
  .md\:shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .md\:translate-x-0 {
    --tw-translate-x: 0px !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  .md\:scale-110 {
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .md\:gap-0 {
    gap: 0 !important;
  }
  .md\:gap-\[30px\] {
    gap: 30px !important;
  }
  .md\:bg-black\/20 {
    background-color: rgb(22 28 45 / 0.2) !important;
  }
  .md\:bg-gradient-to-b {
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(var(--tw-gradient-stops))
    ) !important;
    background-image: linear-gradient(
      to bottom,
      var(--tw-gradient-stops)
    ) !important;
  }
  .md\:from-black {
    --tw-gradient-from: #161c2d !important;
    --tw-gradient-to: rgb(22 28 45 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .md\:from-transparent {
    --tw-gradient-from: transparent !important;
    --tw-gradient-to: rgb(0 0 0 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .md\:via-black\/80 {
    --tw-gradient-to: rgb(22 28 45 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), rgb(22 28 45 / 0.8),
      var(--tw-gradient-to) !important;
  }
  .md\:via-indigo-600\/80 {
    --tw-gradient-to: rgb(79 70 229 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.8),
      var(--tw-gradient-to) !important;
  }
  .md\:to-black {
    --tw-gradient-to: #161c2d !important;
  }
  .md\:to-indigo-800 {
    --tw-gradient-to: #3730a3 !important;
  }
  .md\:p-8 {
    padding: 2rem !important;
  }
  .md\:p-4 {
    padding: 1rem !important;
  }
  .md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .md\:py-60 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .md\:py-44 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .md\:py-52 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .md\:py-72 {
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }
  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .md\:pb-24 {
    padding-bottom: 6rem !important;
  }
  .md\:pt-40 {
    padding-top: 10rem !important;
  }
  .md\:pb-0 {
    padding-bottom: 0 !important;
  }
  .md\:pt-60 {
    padding-top: 15rem !important;
  }
  .md\:pb-64 {
    padding-bottom: 16rem !important;
  }
  .md\:pt-44 {
    padding-top: 11rem !important;
  }
  .md\:pt-0 {
    padding-top: 0 !important;
  }
  .md\:pt-24 {
    padding-top: 6rem !important;
  }
  .md\:pb-36 {
    padding-bottom: 9rem !important;
  }
  .md\:pt-12 {
    padding-top: 3rem !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
  .md\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
  .md\:text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }
  .md\:text-\[32px\] {
    font-size: 32px !important;
  }
  .md\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
  .md\:leading-normal {
    line-height: 1.5 !important;
  }
  .md\:opacity-100 {
    opacity: 1 !important;
  }
  .md\:before\:mx-auto::before {
    content: var(--tw-content) !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .after\:md\:bottom-12::after {
    content: var(--tw-content) !important;
    bottom: 3rem !important;
  }
  .md\:after\:m-auto::after {
    content: var(--tw-content) !important;
    margin: auto !important;
  }
  .md\:after\:mx-auto::after {
    content: var(--tw-content) !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .after\:md\:h-\[55rem\]::after {
    content: var(--tw-content) !important;
    height: 55rem !important;
  }
  [dir="ltr"] .ltr\:md\:left-2\/4 {
    left: 50% !important;
  }
  [dir="ltr"] .ltr\:md\:right-auto {
    right: auto !important;
  }
  [dir="ltr"] .ltr\:md\:left-0 {
    left: 0 !important;
  }
  [dir="ltr"] .ltr\:md\:-left-10 {
    left: -2.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:left-10 {
    left: 2.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:right-14 {
    right: 3.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:-left-\[10\%\] {
    left: -10% !important;
  }
  [dir="ltr"] .ltr\:md\:right-\[10\%\] {
    right: 10% !important;
  }
  [dir="ltr"] .ltr\:md\:-left-16 {
    left: -4rem !important;
  }
  [dir="ltr"] .ltr\:md\:-left-14 {
    left: -3.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:mr-6 {
    margin-right: 1.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:mr-4 {
    margin-right: 1rem !important;
  }
  [dir="ltr"] .ltr\:md\:ml-0 {
    margin-left: 0 !important;
  }
  [dir="ltr"] .ltr\:md\:ml-auto {
    margin-left: auto !important;
  }
  [dir="ltr"] .ltr\:md\:ml-6 {
    margin-left: 1.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:mr-8 {
    margin-right: 2rem !important;
  }
  [dir="ltr"] .ltr\:md\:ml-8 {
    margin-left: 2rem !important;
  }
  [dir="ltr"] .ltr\:md\:mr-auto {
    margin-right: auto !important;
  }
  [dir="ltr"] .ltr\:md\:-translate-x-2\/4 {
    --tw-translate-x: -50% !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  [dir="ltr"] .ltr\:md\:translate-x-0 {
    --tw-translate-x: 0px !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  [dir="ltr"] .ltr\:md\:bg-gradient-to-r {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(var(--tw-gradient-stops))
    ) !important;
    background-image: linear-gradient(
      to right,
      var(--tw-gradient-stops)
    ) !important;
  }
  [dir="ltr"] .ltr\:md\:bg-gradient-to-l {
    background-image: -webkit-gradient(
      linear,
      right top,
      left top,
      from(var(--tw-gradient-stops))
    ) !important;
    background-image: linear-gradient(
      to left,
      var(--tw-gradient-stops)
    ) !important;
  }
  [dir="ltr"] .ltr\:md\:pl-10 {
    padding-left: 2.5rem !important;
  }
  [dir="ltr"] .ltr\:md\:pl-4 {
    padding-left: 1rem !important;
  }
  [dir="ltr"] .ltr\:md\:text-left {
    text-align: left !important;
  }
  [dir="ltr"] .ltr\:md\:text-right {
    text-align: right !important;
  }
  [dir="ltr"] .ltr\:md\:before\:right-0::before {
    content: var(--tw-content) !important;
    right: 0 !important;
  }
  [dir="ltr"] .ltr\:md\:before\:left-0::before {
    content: var(--tw-content) !important;
    left: 0 !important;
  }
  [dir="ltr"] .ltr\:after\:md\:-left-20::after {
    content: var(--tw-content) !important;
    left: -5rem !important;
  }
  [dir="ltr"] .ltr\:md\:after\:right-0::after {
    content: var(--tw-content) !important;
    right: 0 !important;
  }
  [dir="ltr"] .ltr\:md\:after\:left-0::after {
    content: var(--tw-content) !important;
    left: 0 !important;
  }
  [dir="rtl"] .rtl\:md\:right-2\/4 {
    right: 50% !important;
  }
  [dir="rtl"] .rtl\:md\:left-auto {
    left: auto !important;
  }
  [dir="rtl"] .rtl\:md\:right-0 {
    right: 0 !important;
  }
  [dir="rtl"] .rtl\:md\:-right-10 {
    right: -2.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:right-10 {
    right: 2.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:left-14 {
    left: 3.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:-right-\[10\%\] {
    right: -10% !important;
  }
  [dir="rtl"] .rtl\:md\:left-\[10\%\] {
    left: 10% !important;
  }
  [dir="rtl"] .rtl\:md\:-right-16 {
    right: -4rem !important;
  }
  [dir="rtl"] .rtl\:md\:-right-14 {
    right: -3.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:ml-6 {
    margin-left: 1.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:ml-4 {
    margin-left: 1rem !important;
  }
  [dir="rtl"] .rtl\:md\:mr-0 {
    margin-right: 0 !important;
  }
  [dir="rtl"] .rtl\:md\:mr-auto {
    margin-right: auto !important;
  }
  [dir="rtl"] .rtl\:md\:mr-6 {
    margin-right: 1.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:ml-8 {
    margin-left: 2rem !important;
  }
  [dir="rtl"] .rtl\:md\:mr-8 {
    margin-right: 2rem !important;
  }
  [dir="rtl"] .rtl\:md\:ml-auto {
    margin-left: auto !important;
  }
  [dir="rtl"] .rtl\:md\:translate-x-2\/4 {
    --tw-translate-x: 50% !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  [dir="rtl"] .rtl\:md\:translate-x-0 {
    --tw-translate-x: 0px !important;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }
  [dir="rtl"] .rtl\:md\:bg-gradient-to-l {
    background-image: -webkit-gradient(
      linear,
      right top,
      left top,
      from(var(--tw-gradient-stops))
    ) !important;
    background-image: linear-gradient(
      to left,
      var(--tw-gradient-stops)
    ) !important;
  }
  [dir="rtl"] .rtl\:md\:bg-gradient-to-r {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(var(--tw-gradient-stops))
    ) !important;
    background-image: linear-gradient(
      to right,
      var(--tw-gradient-stops)
    ) !important;
  }
  [dir="rtl"] .rtl\:md\:pr-10 {
    padding-right: 2.5rem !important;
  }
  [dir="rtl"] .rtl\:md\:pr-4 {
    padding-right: 1rem !important;
  }
  [dir="rtl"] .rtl\:md\:text-left {
    text-align: left !important;
  }
  [dir="rtl"] .rtl\:md\:text-right {
    text-align: right !important;
  }
  [dir="rtl"] .rtl\:md\:before\:left-0::before {
    content: var(--tw-content) !important;
    left: 0 !important;
  }
  [dir="rtl"] .rtl\:md\:before\:right-0::before {
    content: var(--tw-content) !important;
    right: 0 !important;
  }
  [dir="rtl"] .rtl\:after\:md\:-right-20::after {
    content: var(--tw-content) !important;
    right: -5rem !important;
  }
  [dir="rtl"] .rtl\:md\:after\:left-0::after {
    content: var(--tw-content) !important;
    left: 0 !important;
  }
  [dir="rtl"] .rtl\:md\:after\:right-0::after {
    content: var(--tw-content) !important;
    right: 0 !important;
  }
}
@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:top-14 {
    top: 3.5rem !important;
  }
  .lg\:bottom-28 {
    bottom: 7rem !important;
  }
  .lg\:bottom-32 {
    bottom: 8rem !important;
  }
  .lg\:bottom-\[315px\] {
    bottom: 315px !important;
  }
  .lg\:bottom-\[335px\] {
    bottom: 335px !important;
  }
  .lg\:-z-2 {
    z-index: -2 !important;
  }
  .lg\:order-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .lg\:order-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .lg\:order-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .lg\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }
  .lg\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }
  .lg\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }
  .lg\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }
  .lg\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }
  .lg\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }
  .lg\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }
  .lg\:col-start-3 {
    grid-column-start: 3 !important;
  }
  .lg\:col-start-2 {
    grid-column-start: 2 !important;
  }
  .lg\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .lg\:mt-0 {
    margin-top: 0 !important;
  }
  .lg\:mt-7 {
    margin-top: 1.75rem !important;
  }
  .lg\:mt-16 {
    margin-top: 4rem !important;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem !important;
  }
  .lg\:-mt-\[330px\] {
    margin-top: -330px !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .lg\:h-\[540px\] {
    height: 540px !important;
  }
  .lg\:h-\[400px\] {
    height: 400px !important;
  }
  .lg\:h-56 {
    height: 14rem !important;
  }
  .lg\:h-20 {
    height: 5rem !important;
  }
  .lg\:h-24 {
    height: 6rem !important;
  }
  .lg\:h-72 {
    height: 18rem !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:w-2\/5 {
    width: 40% !important;
  }
  .lg\:w-2\/3 {
    width: 66.666667% !important;
  }
  .lg\:w-1\/4 {
    width: 25% !important;
  }
  .lg\:w-3\/4 {
    width: 75% !important;
  }
  .lg\:w-11\/12 {
    width: 91.666667% !important;
  }
  .lg\:w-\[400px\] {
    width: 400px !important;
  }
  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }
  .lg\:w-52 {
    width: 13rem !important;
  }
  .lg\:w-72 {
    width: 18rem !important;
  }
  .lg\:w-24 {
    width: 6rem !important;
  }
  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }
  .lg\:w-10\/12 {
    width: 83.333333% !important;
  }
  .lg\:w-\[280px\] {
    width: 280px !important;
  }
  .lg\:w-1\/2 {
    width: 50% !important;
  }
  .lg\:w-4\/5 {
    width: 80% !important;
  }
  .lg\:w-20 {
    width: 5rem !important;
  }
  .lg\:w-1\/5 {
    width: 20% !important;
  }
  .lg\:w-48 {
    width: 12rem !important;
  }
  .lg\:w-\[500px\] {
    width: 500px !important;
  }
  .lg\:max-w-sm {
    max-width: 24rem !important;
  }
  .lg\:max-w-\[240px\] {
    max-width: 240px !important;
  }
  .lg\:max-w-none {
    max-width: none !important;
  }
  .lg\:max-w-768 {
    max-width: 45rem !important;
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .lg\:items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .lg\:gap-6 {
    gap: 1.5rem !important;
  }
  .lg\:gap-0 {
    gap: 0 !important;
  }
  .lg\:rounded-none {
    border-radius: 0 !important;
  }
  .lg\:bg-white {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  }
  .lg\:from-indigo-600\/5 {
    --tw-gradient-from: rgb(79 70 229 / 0.05) !important;
    --tw-gradient-to: rgb(79 70 229 / 0) !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
  }
  .lg\:to-indigo-600\/20 {
    --tw-gradient-to: rgb(79 70 229 / 0.2) !important;
  }
  .lg\:p-6 {
    padding: 1.5rem !important;
  }
  .lg\:p-12 {
    padding: 3rem !important;
  }
  .lg\:p-0 {
    padding: 0 !important;
  }
  .lg\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .lg\:py-44 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .lg\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }
  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .lg\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .lg\:py-52 {
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }
  .lg\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .lg\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }
  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .lg\:pt-24 {
    padding-top: 6rem !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }
  .lg\:pt-36 {
    padding-top: 9rem !important;
  }
  .lg\:pt-44 {
    padding-top: 11rem !important;
  }
  .lg\:pb-24 {
    padding-bottom: 6rem !important;
  }
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-5xl {
    font-size: 3rem !important;
    line-height: 1 !important;
  }
  .lg\:text-\[42px\] {
    font-size: 42px !important;
  }
  .lg\:text-\[150px\] {
    font-size: 150px !important;
  }
  .lg\:text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }
  .lg\:text-\[40px\] {
    font-size: 40px !important;
  }
  .lg\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }
  .lg\:text-\[72px\] {
    font-size: 72px !important;
  }
  .lg\:text-\[64px\] {
    font-size: 64px !important;
  }
  .lg\:text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
  .lg\:leading-normal {
    line-height: 1.5 !important;
  }
  .lg\:leading-\[1\.1\] {
    line-height: 1.1 !important;
  }
  .lg\:leading-relaxed {
    line-height: 1.625 !important;
  }
  .lg\:text-black {
    --tw-text-opacity: 1 !important;
    color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
  }
  .lg\:text-slate-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
  }
  .lg\:shadow-md {
    --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2) !important;
    --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color) !important;
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
  .after\:lg\:h-3::after {
    content: var(--tw-content) !important;
    height: 0.75rem !important;
  }
  [dir="ltr"] .ltr\:lg\:-right-7 {
    right: -1.75rem !important;
  }
  [dir="ltr"] .ltr\:lg\:-right-10 {
    right: -2.5rem !important;
  }
  [dir="ltr"] .ltr\:lg\:left-0 {
    left: 0 !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-5 {
    margin-left: 1.25rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-8 {
    margin-left: 2rem !important;
  }
  [dir="ltr"] .ltr\:lg\:mr-8 {
    margin-right: 2rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-4 {
    margin-left: 1rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-10 {
    margin-left: 2.5rem !important;
  }
  [dir="ltr"] .ltr\:lg\:mr-5 {
    margin-right: 1.25rem !important;
  }
  [dir="ltr"] .ltr\:lg\:mr-6 {
    margin-right: 1.5rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-auto {
    margin-left: auto !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-16 {
    margin-left: 4rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-6 {
    margin-left: 1.5rem !important;
  }
  [dir="ltr"] .ltr\:lg\:ml-12 {
    margin-left: 3rem !important;
  }
  [dir="ltr"] .ltr\:lg\:text-left {
    text-align: left !important;
  }
  [dir="ltr"] .ltr\:after\:lg\:-left-0::after {
    content: var(--tw-content) !important;
    left: 0 !important;
  }
  [dir="rtl"] .rtl\:lg\:-left-7 {
    left: -1.75rem !important;
  }
  [dir="rtl"] .rtl\:lg\:-left-10 {
    left: -2.5rem !important;
  }
  [dir="rtl"] .rtl\:lg\:right-0 {
    right: 0 !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-5 {
    margin-right: 1.25rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-8 {
    margin-right: 2rem !important;
  }
  [dir="rtl"] .rtl\:lg\:ml-8 {
    margin-left: 2rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-4 {
    margin-right: 1rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-10 {
    margin-right: 2.5rem !important;
  }
  [dir="rtl"] .rtl\:lg\:ml-5 {
    margin-left: 1.25rem !important;
  }
  [dir="rtl"] .rtl\:lg\:ml-6 {
    margin-left: 1.5rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-auto {
    margin-right: auto !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-16 {
    margin-right: 4rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-6 {
    margin-right: 1.5rem !important;
  }
  [dir="rtl"] .rtl\:lg\:mr-12 {
    margin-right: 3rem !important;
  }
  [dir="rtl"] .rtl\:lg\:text-right {
    text-align: right !important;
  }
  [dir="rtl"] .rtl\:after\:lg\:-right-0::after {
    content: var(--tw-content) !important;
    right: 0 !important;
  }
  .dark .lg\:dark\:bg-slate-900 {
    --tw-bg-opacity: 1 !important;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
  }
  .dark .lg\:dark\:text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  .dark .lg\:dark\:shadow-gray-800 {
    --tw-shadow-color: #1f2937 !important;
    --tw-shadow: var(--tw-shadow-colored) !important;
  }
}
@media (min-width: 1280px) {
  .xl\:top-20 {
    top: 5rem !important;
  }
  .xl\:bottom-\[420px\] {
    bottom: 420px !important;
  }
  .xl\:bottom-\[440px\] {
    bottom: 440px !important;
  }
  .xl\:p-3 {
    padding: 0.75rem !important;
  }
  [dir="ltr"] .ltr\:xl\:-right-20 {
    right: -5rem !important;
  }
  [dir="rtl"] .rtl\:xl\:-left-20 {
    left: -5rem !important;
  }
}
/*# sourceMappingURL=tailwind.min.css.map */
