/* Template Name: Techwind - Multipurpose Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.6.0
   Created: May 2022
   File: Main Css File
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

// FONTS
@import "custom/fonts";

// general
@import "custom/general";

// components
@import "components/buttons";
@import "components/breadcrumb";

// structure
@import "custom/structure/topnav";
@import "custom/structure/footer";

// pages
@import "custom/pages/helper";
@import "custom/pages/hero";
@import "custom/pages/countdown";
@import "custom/pages/portfolio";
@import "custom/pages/contact";

// Plugins
@import "custom/plugins/swiper-slider";
@import "custom/plugins/testi";
@import "custom/plugins/choices";
@import "custom/plugins/datepicker";
